export var quesList = [
  {
    name: "q1",
    ques: "Do you own, operate or lease aircraft / watercraft?",
    response: false,
  },
  {
    name: "q2",
    ques:
      "Do you have past, present or discontinued operations involve(d) storing, treating, discharging, applying, disposing, or transporting of hazardous material? (e.G. Landfills, wastes, fuel tanks, etc)",
    response: false,
  },
  {
    name: "q3",
    ques: "Do you have any work performed underground or above 15 feet?",
    response: false,
  },
  {
    name: "q4",
    ques:
      "Do you have any work performed on barges, vessels, docks, bridge over water?",
    response: false,
  },
  {
    name: "q5",
    ques: "Are you engaged in any other type of business?",
    response: false,
  },
  {
    name: "q6",
    ques:
      'Are any sub-contractors used? (if "Yes", give % of work subcontracted)',
    response: false,
    type: "percent",
  },
  {
    name: "q7",
    ques: "Do you have any work sublet without certificates of insurance?",
    response: false,
  },
  {
    name: "q8",
    ques: "Is there a written safety program in your operations?",
    response: true,
  },
  {
    name: "q9",
    ques: "Any group transportation provided?",
    response: false,
  },
  {
    name: "q10",
    ques: "Any employees under 16 or over 60 years of age?",
    response: false,
  },
  {
    name: "q11",
    ques: "Any seasonal employees?",
    response: false,
  },
  {
    name: "q12",
    ques: "Is there any volunteer or donated labor?",
    response: false,
  },
  {
    name: "q13",
    ques: "Any employees with physical disabilities?",
    response: false,
  },
  {
    name: "q14",
    ques: "Do employees travel out of state?",
    response: false,
  },
  {
    name: "q15",
    ques: "Are athletic teams sponsored?",
    response: false,
  },
  {
    name: "q16",
    ques: "Are physicals required after offers of employment are made?",
    response: false,
  },
  {
    name: "q17",
    ques: "Any other insurance with this insurer?",
    response: false,
  },
  {
    name: "q18",
    ques:
      "Any prior coverage declined / cancelled / non-renewed in the last three (3) years?",
    response: false,
  },
  {
    name: "q19",
    ques: "Are employee health plans provided?",
    response: true,
  },
  {
    name: "q20",
    ques: "Do any employees perform work for other businesses or subsidiaries?",
    response: false,
  },
  {
    name: "q21",
    ques: "Do you lease employees to or from other employers?",
    response: false,
  },
  {
    name: "q22",
    ques: "Do any employees predominantly work at home?",
    response: false,
  },
  {
    name: "q23",
    ques: "Any tax liens or bankruptcy within the last five (5) years?",
    response: false,
  },
  {
    name: "q24",
    ques:
      "Any undisputed and unpaid worker's compensation premium due from you or any commonly managed or owned enterprises?",
    response: false,
  },
  {
    name: "q25",
    ques: "Are you a safe workplace and do you qualify for safety credit?",
    response: false,
  },
  {
    name: "q26",
    ques:
      "Are you alcohol/drug free and do you qualify for drug free workplace certificate?",
    response: false,
  },
  {
    name: "q27",
    ques: "Contract classification premium adjustment program?",
    response: false,
  },
  {
    name: "q28",
    ques: "Waiver of subrogation?",
    response: false,
  }
];
export var quesListAdd = [
  {
    name: "q25",
    ques: "Are you a safe workplace and do you qualify for safety credit?",
    response: false,
  },
  {
    name: "q26",
    ques:
      "Are you alcohol/drug free and do you qualify for drug free workplace certificate?",
    response: false,
  },
  {
    name: "q27",
    ques: "Contract classification premium adjustment program?",
    response: false,
  },
  {
    name: "q28",
    ques: "Waiver of subrogation?",
    response: false,
  }
];

export var fourthQues = [
  {
    name: "q1",
    ques:
      "Does the business currently have Workers’ Compensation coverage in place?",
    // response: false,
  },
  {
    name: "q2",
    ques:
      "In the past 2 years has the business had two or more Workers' compensation " +
      "Claims or A single claim over 20K or Any Employee Suffered Work related " +
      "Injury requiring more than 2 days off workug free and do you qualify for drug free workplace certificate?",
    // response: false,
  },
  {
    name: "q3",
    ques:
      "Does applicant operate as a discotheque, night club, dance hall, hookah lounge or provide adult entertainment?",
    // response: false,
  },
  {
    name: "q4",
    ques:
      "Does applicant operate as a food truck, meals on wheels, or street vendor/food concessionaire?",
    // response: false,
  },
  {
    name: "q5",
    ques: "Is the applicant open 24 hours?",
    // response: false,
  },
  {
    name: "q6",
    ques:
      "Are ANY of the following true?: The applicant makes more than 10 deliveries " +
      "per day on average, the applicant makes deliveries after 10pm, the applicant " +
      "makes deliveries on foot or by bicycle.",
    // response: false,
  },
  {
    name: "q7",
    ques:
      "Does the applicant make any deliveries using a motorized scooter or moped?",
    // response: false,
  },
];

export var companyProfileAddQuestions = [
  {
    name: "qc1",
    ques: "Are you the incumbent agent?",
    response: true,
  },
  {
    name: "qc2",
    ques: "Is the applicant a Non-Profit Operation?",
    response: false,
  },
  {
    name: "qc3",
    ques: "Are you interested in both PEO and PAYGO Quotes?",
    response: true,
  },
];
