import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { demoAuthUrl } from "../../config";

function DemoRouteHandler() {
  let [displayText, updateDisplayText] = useState("Redirecting...");
  async function signInDemoUser(path, uuid, loginType) {
    try {
      let credentialResponse = await axios.post(
        demoAuthUrl + `/api/verifyDemoRedirectUuid`,
        { path, loginType, instance: "ies", uuid }
      );
      let { username, password } = credentialResponse.data;
      await Auth.signOut();
      try {
        await Auth.signIn(username.toLowerCase(), password);
      } catch (error) {
        console.log(error);
      }
      window.location.href = `/${path}`;
    } catch (error) {
      console.log(error);
      updateDisplayText("Error");
    }
  }
  useEffect(() => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let path = urlParams.get("path");
    let uuid = urlParams.get("id");
    let loginType = urlParams.get("loginType");
    signInDemoUser(path, uuid, loginType);
  });

  return <div>{displayText}</div>;
}

export default DemoRouteHandler;
