import React, { Component } from "react";
import { LibertateContext } from "./Context";

export class LibertateContextProvider extends Component {
  state = {
    perStateQuoteTableData: {},
    programs: [],
    masterWithoutFundRateMap: {},
    programStateType: {},
    peoData: {},
    excludedClassCodeMap: {},
    currentCarrierMap: {},
    stateCarrierData: {},
    uwNetRates: {},
    uwFileList: {
      third: {},
    },
    carrierBasedData: {},
    pricingToolData: {},
    overridesMap: {},
    calculateClicked: false,
  };

  updateSelectedCarrierMap = async (
    carrier,
    value,
    program,
    state,
    state_value,
    selectedStateList
  ) => {
    let { currentCarrierMap, perStateQuoteTableData } = this.state;
    let _currentCarrierMap = JSON.parse(JSON.stringify(currentCarrierMap));

    _currentCarrierMap[carrier] = value;

    if (state && program) {
      if (perStateQuoteTableData[state][program]) {
        perStateQuoteTableData[state].checked[program] = state_value;
      }
    }

    if (!state && program) {
      for (let _state in perStateQuoteTableData) {
        if (perStateQuoteTableData[_state][program]) {
          perStateQuoteTableData[_state].checked[program] = value;
        }
      }
    }

    this.setState({
      perStateQuoteTableData,
      currentCarrierMap: _currentCarrierMap,
    });
  };

  setPricingToolData = (data) => {
    try {
      let overridesMap = data?.overridesMap;
      let calculateClicked = false;

      data.selectedCarrier = overridesMap?.selectedCarrier;

      if (data.selectedCarrier) {
        calculateClicked = true;
      }

      delete data.overridesMap;
      this.setState({ pricingToolData: data, overridesMap, calculateClicked });
    } catch (error) {}
  };

  updatePricingToolData = (key, value) => {
    try {
      let pricingToolData = JSON.parse(
        JSON.stringify(this.state.pricingToolData)
      );
      pricingToolData[key] = value;
      this.setState({ pricingToolData });
    } catch (error) {
      console.log(error);
    }
  };

  updateSelectedCarrier = (carrier) => {
    try {
      let { pricingToolData } = JSON.parse(JSON.stringify(this.state));
      pricingToolData.selectedCarrier = carrier;
      this.setState({ pricingToolData });
    } catch (error) {}
  };

  render() {
    let {
      perStateQuoteTableData,
      programs,
      masterWithoutFundRateMap,
      programStateType,
      peoData,
      excludedClassCodeMap,
      currentCarrierMap,
      stateCarrierData,
      uwNetRates,
      uwFileList,
      carrierBasedData,
      pricingToolData,
      overridesMap,
      calculateClicked,
    } = this.state;

    return (
      <LibertateContext.Provider
        value={{
          perStateQuoteTableData,
          programs,
          masterWithoutFundRateMap,
          programStateType,
          peoData,
          excludedClassCodeMap,
          currentCarrierMap,
          stateCarrierData,
          uwNetRates,
          uwFileList,
          carrierBasedData,
          overridesMap,
          pricingToolData,
          calculateClicked,
          setPricingToolData: this.setPricingToolData,
          updatePricingToolData: this.updatePricingToolData,
          updateSelectedCarrier: this.updateSelectedCarrier,
          setUwNetRates: (data) => {
            this.setState({ uwNetRates: data });
          },
          updateSelectedCarrierMap: this.updateSelectedCarrierMap,
          setPerStateQuoteTableData: (data) => {
            this.setState({ perStateQuoteTableData: data });
          },
          setPrograms: (data) => {
            this.setState({ programs: data });
          },
          setMasterWithoutFundRateMap: (data) => {
            this.setState({ masterWithoutFundRateMap: data });
          },
          setProgramStateType: (data) => {
            this.setState({ programStateType: data });
          },
          setPeoData: (data) => {
            this.setState({ peoData: data });
          },
          setExcludedClassCodeMap: (data) => {
            this.setState({ excludedClassCodeMap: data });
          },
          setCurrentCarrierMap: (data) => {
            this.setState({ currentCarrierMap: data });
          },
          setStateCarrierData: (data) => {
            this.setState({ stateCarrierData: data });
          },
          setUwFileList: (data) => {
            this.setState({ uwFileList: data });
          },
          setCarrierBasedData: (data) => {
            this.setState({ carrierBasedData: data });
          },
        }}
      >
        {this.props.children}
      </LibertateContext.Provider>
    );
  }
}
