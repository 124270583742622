import React, { Component } from "react";
import DescriptionCode from "../../subcompo/DescriptionCode";
import { NumericFormat } from "react-number-format";
import { Form } from "react-bootstrap";

import axios from "axios";
class Newcode extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.classCodesInfo) !==
      JSON.stringify(this.state.classCodesInfo)
    ) {
      this.setState({ classCodesInfo: this.props.classCodesInfo });
    }
  }

  componentDidMount() {
    const { classCodesInfo, addressBlockNo, addressChildNo, handleMinMax } =
      this.props;
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    for (let index in classCodesInfo) {
      if (currProspect && classCodesInfo[index].classCodeDescription) {
        let childrenLoc = currProspect.childrenLoc;
        if (
          Object.keys(classCodesInfo[index].classCodeDescription).length === 0
        ) {
          classCodesInfo[index].classCodeDescription =
            childrenLoc[addressBlockNo].classCodesInfo[
              index
            ].classCodeDescription;
        }
      }
    }
    handleMinMax(addressBlockNo, addressChildNo);
    this.setState({ classCodesInfo: classCodesInfo });
  }
  conditional_Render_FT_PT = (value) => {
    let style = {};
    if (window.screen.width < 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };

      return style;
    }
    if (value === "FT" && window.screen.width > 993) {
      style = {
        width: "13%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "PT" && window.screen.width > 993) {
      style = {
        width: "14%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "annual" && window.screen.width > 993) {
      style = {
        width: "18%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "WC" && window.screen.width > 993) {
      style = {
        width: "16%",
        paddingInline: "15px",
      };
      return style;
    }
  };
  render() {
    const {
      addressChildNo,
      addressBlockNo,
      state,
      removeClassCode,
      updateClassCodesInfo,
      handleMinMax,
      updateLocationError,
    } = this.props;

    const { classCodesInfo } = this.state;

    let codeCount = 1;
    const descriptionError =
      classCodesInfo &&
      classCodesInfo?.[addressChildNo] &&
      classCodesInfo?.[addressChildNo]?.classCodeDescription &&
      classCodesInfo?.[addressChildNo]?.classCodeDescription?.error;
    console.log("descriptionError", descriptionError);
    try {
      codeCount = Object.keys(classCodesInfo).length;
    } catch (e) {}
    let deleteIconStyle =
      window.innerWidth >= 850
        ? {
            width: "4%",
            display: "flex",
            alignItems: "center",
            marginTop: "-1.2rem",
            marginLeft: "-1rem",
          }
        : {
            width: "4%",
            display: "flex",
            alignItems: "center",
            marginLeft: "50%",
            paddingBottom: "2.5%",
          };

    return (
      <div className="row calcPremium grey Workerscomp_margin">
        <div style={{ width: "8%", paddingInline: "15px" }}></div>
        <DescriptionCode
          addressBlockNo={addressBlockNo}
          addressChildNo={addressChildNo}
          state={(state || "").toLowerCase()}
          handleMinMax={handleMinMax}
          classCodesInfo={classCodesInfo}
          updateLocationError={updateLocationError}
          updateClassCodesInfo={updateClassCodesInfo}
          descriptionError={descriptionError}
          key={`${addressBlockNo}-${addressChildNo}-descCode`}
        />
        {/* <span
          id={"invalidCode" + addressBlockNo + addressChildNo}
          style={{ color: "red" }}
        ></span> */}
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("FT")}
        >
          <Form.Group className="form-group">
            <label
              for={"classCodes" + addressBlockNo + addressChildNo + "1"}
              title="Number of full time employees"
            >
              <b className="font-family-montserrat-semi-bold"># Full-Time</b>
            </label>
            <NumericFormat
              title="Number of full time employees"
              id={"classCodes" + addressBlockNo + addressChildNo + "1"}
              isNumericString={true}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "ft",
                  event.target.value.trim()
                );
              }}
              style={{
                outline: "none",
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].ft &&
                  classCodesInfo[addressChildNo].ft.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo && classCodesInfo[addressChildNo].ft
                  ? classCodesInfo[addressChildNo].ft.value
                  : ""
              }
              disabled={false}
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              name="FT"
              className="text-center"
              format="#####"
              placeholder="# FT"
              type="text"
              autoComplete="off"
              onKeyDown={(event) => {
                if (event.key === "-" || event.key === "−") {
                  event.preventDefault(); // Prevent typing the '-' character
                }
              }}
            />
          </Form.Group>
        </div>
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("PT")}
        >
          <Form.Group className="form-group">
            <label
              for={"classCodes" + addressBlockNo + addressChildNo + "2"}
              title="Number of part time employees"
            >
              <b className="font-family-montserrat-semi-bold"># Part-Time</b>
            </label>
            <NumericFormat
              title="Number of part time employees"
              id={"classCodes" + addressBlockNo + addressChildNo + "2"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "pt",
                  event.target.value.trim()
                );
              }}
              style={{
                outline: "none",
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].pt &&
                  classCodesInfo[addressChildNo].pt.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo && classCodesInfo[addressChildNo].pt
                  ? classCodesInfo[addressChildNo].pt.value
                  : ""
              }
              disabled={false}
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              onKeyDown={(event) => {
                if (event.key === "-" || event.key === "−") {
                  event.preventDefault(); // Prevent typing the '-' character
                }
              }}
              name="PT"
              className="text-center"
              format="#####"
              placeholder="# PT"
              type="text"
              autoComplete="off"
            />
          </Form.Group>
        </div>
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("annual")}
        >
          <Form.Group className="form-group">
            <label
              for={"classCodes" + addressBlockNo + addressChildNo + "3"}
              title="Annual Payroll"
            >
              <b className="font-family-montserrat-semi-bold">
                Annual Payroll *
              </b>
            </label>
            <NumericFormat
              title="Annual Payroll"
              id={"classCodes" + addressBlockNo + addressChildNo + "3"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "payroll",
                  event.target.value
                );
              }}
              style={{
                outline: "none",
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].payroll &&
                  classCodesInfo[addressChildNo].payroll.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo && classCodesInfo[addressChildNo].payroll
                  ? classCodesInfo[addressChildNo].payroll.value
                  : ""
              }
              disabled={false}
              name="payroll"
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              placeholder="Annual Payroll"
              thousandSeparator={true}
              prefix={"$"}
              className="text-center"
              type="text"
              autoComplete="off"
              onKeyDown={(event) => {
                if (event.key === "-" || event.key === "−") {
                  event.preventDefault(); // Prevent typing the '-' character
                }
              }}
            />
          </Form.Group>
        </div>
        {/* <div
          className="text-left grey"
          style={{ width: "16%", paddingInline: "15px" }}
        >
          <Form.Group>
            <label
              for={"pricing" + addressBlockNo + addressChildNo + "4"}
              title="Requested W/C Pricing %"
              style={{ display: "flex", marginTop: "-1.5rem" }}
            >
              <b className="font-family-montserrat-semi-bold">
                Requested W/C Pricing %
              </b>
            </label>
            <NumericFormat
              title="Requested W/C Pricing %"
              id={"pricing" + addressBlockNo + addressChildNo + "3"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "pricing",
                  event.target.value
                );
              }}
              fixedDecimalScale = {true}
              decimalScale = {2}
              style={{
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].pricing &&
                  classCodesInfo[addressChildNo].pricing.error
                    ? "red"
                    : "",
                backgroundColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].pricing &&
                  classCodesInfo[addressChildNo].pricing.disabled
                    ? "#c2c4c5"
                    : "",
              }}
              value={
                classCodesInfo && classCodesInfo[addressChildNo].pricing
                  ? classCodesInfo[addressChildNo].pricing.value
                  : ""
              }
              disabled={
                classCodesInfo &&
                classCodesInfo[addressChildNo] &&
                classCodesInfo[addressChildNo].pricing &&
                classCodesInfo[addressChildNo].pricing.disabled
              }
              suffix="%"
              name="pricing"
              placeholder="W/C Pricing%"
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              className="text-center"
              type="text"
              autoComplete="off"
            />
          </Form.Group>
        </div> */}
        <div
          className="text-center code-delete"
          // style={{ width: "4%", paddingInline: "15px" }}
          style={deleteIconStyle}
        >
          {codeCount > 1 && (
            <button
              id={"delete-class-code" + addressBlockNo + addressChildNo + "4"}
              type="button"
              className="rmvLoc btnInvisible transpButton"
              data-address={addressBlockNo}
              data-classno={addressChildNo}
            >
              <img
                onClick={(event) => {
                  removeClassCode(addressBlockNo, addressChildNo);
                }}
                src={require("../../../images/delete4.png")}
                data-address={addressBlockNo}
                data-classno={addressChildNo}
                alt=""
                className="delete_records_btn"
              />
            </button>
          )}
        </div>
        {/* {
          <div className="col-sm-4 relDiv rangeSlider">
            <div className="row justify-content-center">
              <div id="minVal" className="premVal">
                <span className="minValueCC">{min}</span>
              </div>
              <div className="barBG"></div>
              <div id="maxVal" className="premVal">
                <span className="manValueCC">{max}</span>
              </div>
            </div>
          </div>
        } */}
      </div>
    );
  }
}
export default Newcode;
