import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UnderWriterFlow from "./component/pages/UnderWriterFlow";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import PanelUW from "./component/pages/PanelUW";
import Tabs from "./component/pages/Tabs";
import DashboardLibertate from "./component/pages/DashboardLibertate";
import DashboardRater from "./component/pages/DashboardRater";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";
import { NewRates } from "./newcomponent/NewRates/NewRates";
import DataUpload from "./component/pages/DataUpload";
import RaterUpload from "./component/pages/RaterUpload";
import RiskProfile from "./component/subcompo/sections-quote/RiskProfile";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";
import { LibertateContextProvider } from "./context/ContextProvider";
import { Auth } from "aws-amplify";

function App(props) {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      (async () => {
        try {
          let sessionData = await Auth.currentAuthenticatedUser();
          console.log("++sess", sessionData);
          setSessionData(sessionData);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      })();
    }
  }, []);

  return !loading ? (
    <LibertateContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/demo" element={<DemoRouteHandler />} />
          <Route
            path="/RaterUpload"
            element={
              <PrivateGroupRoute
                sessionData={sessionData}
                allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
                exact
                path="/RaterUpload"
              >
                <RaterUpload />
              </PrivateGroupRoute>
            }
          />
          <Route
            path="/DataUpload"
            element={
              <PrivateGroupRoute
                sessionData={sessionData}
                allowedGroups={[
                  process.env.REACT_APP_UPLOAD_COMPANY,
                  process.env.REACT_APP_UPLOAD_RATER,
                ]}
                exact
                path="/DataUpload"
              >
                <DataUpload />
              </PrivateGroupRoute>
            }
          />
          <Route
            path="/Dashboard"
            element={
              <PrivateGroupRoute
                sessionData={sessionData}
                allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                exact
                path="/Dashboard"
              >
                <DashboardLibertate />
              </PrivateGroupRoute>
            }
          />
          <Route
            path="/Quote"
            element={
              <PrivateGroupRoute
                sessionData={sessionData}
                allowedGroups={[process.env.REACT_APP_GET_QUOTE_GROUP]}
                exact
                path="/Quote"
              >
                <NewQuote />
              </PrivateGroupRoute>
            }
          />
          <Route
            path="/Rates"
            element={
              <PrivateGroupRoute
                sessionData={sessionData}
                allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
                exact
                path="/Rates"
              >
                <NewRates />
              </PrivateGroupRoute>
            }
          />
          <Route path="/riskprofile" element={<RiskProfile />} />
          <Route
            path="/UwPortal"
            element={
              <PrivateGroupRoute
                sessionData={sessionData}
                allowedGroups={[process.env.REACT_APP_UW_GROUPS]}
                exact
                path="/UwPortal"
              >
                <UnderWriterFlow />
              </PrivateGroupRoute>
            }
          />
          <Route
            path="/RaterDashboard"
            element={
              <PrivateGroupRoute
                sessionData={sessionData}
                allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                exact
                path="/RaterDashboard"
              >
                <DashboardRater />
              </PrivateGroupRoute>
            }
          />
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </LibertateContextProvider>
  ) : (
    <></>
  );
}

export default App;
