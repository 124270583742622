import React, { useCallback, memo } from "react";
import SmallLoader from "../../common/SmallLoader";
import { NumericFormat } from "react-number-format";
import { formatToDollar } from "../sections-quote/pricing-tool/utils/utils";
const Administrative = ({
  administrativeData,
  tableName,
  HeadingValue,
  setAdministrativeData,
  administrativeDataLoader,
}) => {
  const handleInputTextChange = (e, questionId) => {
    let { value } = e.target;
    setAdministrativeData(tableName, questionId, value);
  };

  const handleInputNumberChange = (e, questionId) => {
    let { value } = e;
    value = Number(value);
    setAdministrativeData(tableName, questionId, value);
  };

  const handleSelectTextChange = (e, questionId) => {
    let { value } = e.target;
    setAdministrativeData(tableName, questionId, value);
  };

  const handleSelectNumberChange = (e, questionId) => {
    let { value } = e.target;
    value = value.replace(/[$,]/g, "");
    value = parseFloat(value);
    setAdministrativeData(tableName, questionId, value);
  };

  return (
    <div className="mt-5 mb-5">
      <h2
        style={{ color: "#A33336", fontWeight: "700", marginLeft: "18px" }}
        className=" mb-4 mt-2"
      >
        {HeadingValue}
      </h2>
      {
        <div className="container">
          {administrativeData &&
            Object.keys(administrativeData).length > 0 &&
            Object.values(administrativeData).map((value, index) => (
              <div key={index} className="row mb-2 align-items-baseline">
                <div className="col-md-2">
                  {value?.dropDown === "yes" ? (
                    typeof value.response === "string" ? (
                      <select
                        className="form-select"
                        style={{
                          width: "100%",
                          padding: "2px 20px",
                          fontSize: "14px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                        value={value?.response}
                        onChange={(e) =>
                          handleSelectTextChange(e, value?.questionId)
                        }
                      >
                        {value?.options.map((option, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        style={{
                          width: "100%",
                          padding: "2px 20px",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                        value={formatToDollar(value?.response)}
                        onChange={(e) =>
                          handleSelectNumberChange(e, value?.questionId)
                        }
                      >
                        {value?.options.map((option, index) => (
                          <option key={index}>{formatToDollar(option)}</option>
                        ))}
                      </select>
                    )
                  ) : typeof value.response === "string" ? (
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        textAlign: "center",
                        textTransform: "capitalize",
                      }}
                      value={`${value?.response}`}
                      onChange={(e) =>
                        handleInputTextChange(e, value?.questionId)
                      }
                    />
                  ) : (
                    <NumericFormat
                      type="text"
                      className="form-control"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                      prefix={"$"}
                      decimalScale={2}
                      allowNegative={false}
                      fixedDecimalScale
                      value={value?.response}
                      onValueChange={(values, sourecInof) =>
                        handleInputNumberChange(values, value?.questionId)
                      }
                    />
                  )}
                </div>
                <div className="col-md-10">
                  <p className="form-label fw-bold peoChargefont">
                    {value?.question}
                  </p>
                </div>
              </div>
            ))}
        </div>
      }
    </div>
  );
};

export default memo(Administrative);
