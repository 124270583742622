// here we shall design the new progress bar and see how to resuse it
import React from "react";
const width = {
  one: "25%",
  two: "50%",
  three: "75%",
  four: "100%",
};

function NewProgress(props) {
  let stage = sessionStorage.getItem("formStage");

  let commonStyles = {
    display: "block",
    height: "100%",
    borderRadius: "8px",
  };

  return (
    <div style={{ marginBottom: "35px" }}>
    </div>
    // <div className="color-bar" style={{ direction: "rtl" }}>
    //   <span
    //     style={{ width: "75%", backgroundColor: "#e5903e", ...commonStyles }}
    //   >
    //     <span
    //       style={{ width: "65%", backgroundColor: "#3e9ccc", ...commonStyles }}
    //     >
    //       <span
    //         style={{
    //           width: "50%",
    //           backgroundColor: "#c0224d",
    //           ...commonStyles,
    //         }}
    //       ></span>
    //     </span>
    //   </span>
    // </div>
  );
}

export default NewProgress;
