import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import LoadingOverlay from "react-loading-overlay";

const headingMap = {
  first: "Upload Loss Runs",
  second: "Upload Other Documents",
  third: "Upload Docs",
};

const folderMap = {
  first: "LossRun",
  second: "OtherDocuments",
  third: "UwDocs",
};

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: this.props.fileList,
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.fileList) !== JSON.stringify(prevProps.fileList)
    ) {
      this.setState({ fileList: this.props.fileList });
    }
  }

  updateFileList = (name, newFile) => {
    let { fileList } = this.state;
    if (fileList[name][newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });
      return;
    }
    fileList[name][newFile.name] = newFile;
    this.setState({ fileList, errMsg: undefined, uploadMsg: undefined });
  };

  removeFile = (name, fileName) => {
    let { fileList } = this.state;
    delete fileList[name][fileName];
    this.setState({ fileList });
  };

  uploadFile = (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl + "/api/uploadDocs", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve();
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadDocs ", error);
          reject();
        });
    });
  };

  updateLibertateUserStatusTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/updateUserStatusTable", dataToSend)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-updateLibertateUserStatusTable", error);
          reject();
        });
    });
  };

  updateLibertateUserTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/userTableData/update", dataToSend)
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          console.log("Error:", err);
          reject();
        });
      console.log("inserted successfully in user table yay");
    });
  };

  handleUpload = () => {
    let { updatePopUpData, fileKeyName } = this.props;
    updatePopUpData({
      show: true,
      title: "Do you wish to continue?",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Files once uploaded cannot be deleted
          </span>
        </>
      ),
      acceptBtn: "Yes",
      acceptBtnCallback: async () => {
        try {
          let { fileList } = this.state;
          if (
            Object.keys(fileList["second"] ?? 0)?.length !== 0 ||
            Object.keys(fileList["first"] ?? 0)?.length !== 0 ||
            Object.keys(fileList["third"] ?? 0)?.length !== 0
          ) {
            let address = JSON.parse(sessionStorage.getItem("address"));
            let currProspectDetails = JSON.parse(
              sessionStorage.getItem("currProspect")
            );
            let user_id = sessionStorage.getItem("user_id");
            let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));

            this.setState({ uploadingInProgress: true });

            const email = user_id;
            const folder1 = `${email}/${quoteData.uuid}_${quoteData.date}`;

            let promiseList = [],
              fileListToStore = [];

            for (let key in fileList) {
              if (fileList[key]) {
                for (let fileName in fileList[key]) {
                  let fileType = fileName.split(".").reverse()[0];
                  const folder2 = folderMap[key];
                  fileListToStore.push({
                    key: `${folder1}/${folder2}/${fileName}`,
                    fileType,
                    keyToFile: key,
                  });

                  const postDataS3 = {
                    folder1,
                    folder2,
                    fileName,
                    fileType,
                  };
                  if (fileList[key][fileName] !== "#empty")
                    promiseList.push(
                      this.uploadFile(fileList[key][fileName], postDataS3)
                    );
                }
              }
            }

            Promise.all(promiseList)
              .then((res) => {
                let req_body = {
                  user_email_id: email,
                  address: address,
                  timestamp: quoteData.date,
                  uuid: quoteData.uuid,
                  peo: currProspectDetails.peoDetails.selectedPeo,
                  [fileKeyName]: fileListToStore,
                };
                const fileListUserTable = [];
                for (let key in fileListToStore) {
                  fileListUserTable.push(fileListToStore[key]["key"]); //.key;
                }
                let req_body_user_table = {
                  user_email_id: email,
                  address: address,
                  uploadTimestamp: quoteData.date,
                  [fileKeyName]: fileListUserTable,
                };

                this.updateLibertateUserStatusTable(req_body)
                  .then((res) => {
                    this.setState({ uploadingInProgress: false });
                    this.setState({
                      uploadMsg: "files uploaded",
                      errMsg: undefined,
                    });
                  })
                  .catch((error) => console.log("error in API", error));
                  this.updateLibertateUserTable(req_body_user_table)
                  .then((res) => {
                    this.setState({ uploadingInProgress: false });
                    this.setState({
                      uploadMsg: "files uploaded",
                      errMsg: undefined,
                    });
                  })
                  .catch((error) => console.log("error in API", error));
              })
              .catch((error) => {
                console.log("error: ", error);
                this.setState({ uploadingInProgress: false });
                this.setState({ errMsg: "Error", uploadMsg: undefined });
              });
          } else {
            this.setState({
              errMsg: "No files uploaded",
            });
          }
        } catch (error) {
          console.log("error: ", error);
        }
      },
    });
  };

  render() {
    let { fileList, uploadingInProgress, uploadMsg, errMsg } = this.state;
    let { displayArray } = this.props;
    return (
      <LoadingOverlay active={uploadingInProgress} spinner>
        <div className="dropZonesContainer">
          <div className="row mb-3 ">
            {displayArray[0] === true ? (
              <div className="col-sm-12 col-md-12 col-lg-6 uploadlossruns">
                <CustomDropZone
                  name="first"
                  fileList={fileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  headingMap={headingMap}
                  uploadMsg={uploadMsg}
                />
              </div>
            ) : (
              <></>
            )}
            {displayArray[1] === true ? (
              <div className="col-sm-12 col-md-12 col-lg-6">
                <CustomDropZone
                  name="second"
                  fileList={fileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  headingMap={headingMap}
                  uploadMsg={uploadMsg}
                  extraTest={" (eg. Payroll registers)"}
                />
              </div>
            ) : (
              <></>
            )}
            {displayArray[2] === true ? (
              <div className="col-sm-12 col-md-12 col-lg-12">
                <CustomDropZone
                  name="third"
                  fileList={fileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  headingMap={headingMap}
                  uploadMsg={uploadMsg}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex flex-column">
            <pre
              className={
                "text-center text-capitalize " +
                (uploadMsg ? "text-success" : "text-danger")
              }
            >
              {uploadMsg || errMsg}
            </pre>
            <button
              className="btn btn-sm font-family-montserrat-regular "
              style={{
                width: "min-content",
                color: "white",
                alignSelf: "center",
                backgroundColor: "#008dda",
              }}
              onClick={(event) => {
                event.preventDefault();
                this.handleUpload();
              }}
              disabled={uploadingInProgress}
            >
              Upload
            </button>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
