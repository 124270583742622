import React from "react";
import Header from "../common/Header";
import DropZones from "../common/DropZones2";
import SideTab from "../../newcomponent/common/SideTab";
let isMobile = window.innerWidth < 900;
class DataUpload extends React.Component {
  componentDidMount() {
    let currProspect = sessionStorage.getItem("currProspect");
    if (currProspect) {
      sessionStorage.removeItem("currProspect");
    }
  }
  render() {
    return (
      <>
        <Header />
        <div className="row  bg-white">{isMobile ? <SideTab /> : ""}</div>
        <div className="upload-heading my-0 mx-auto text-center mt-5 mb-5">
          <h1 className="m-0">Upload Company Profile Data</h1>
        </div>
        <DropZones />
      </>
    );
  }
}
export default DataUpload;
