import React, { useState, useEffect } from "react";
import styles from "./Computation.module.scss";
import {
  createComputationOtherFactorsTable,
  createComputationProfitabilityTable,
} from "./utils/utils";
import { Auth } from "aws-amplify";

let mobile_windows_width = window.innerWidth;

function Computation({ data, selectedCarrier }) {
  const { otherFactors, profitabilityData } = data || {};

  return (
    <section className={styles.computationSection}>
      <div className="pink-header p-0 bg-white align-items-center">
        <div className="font-family-montserrat-bold headings">Computation</div>
      </div>
      <div className={styles.sectionContentContainer}>
        <div className={`sectionContent ${styles.sectionContent}`}>
          <div className={styles.otherFactorsSection}>
            <h5 className={styles.blockHeader}>Other Factors</h5>
            <div className={styles.otherFactorsContainer}>
              {createComputationOtherFactorsTable(
                otherFactors?.[selectedCarrier],
                selectedCarrier,
                styles
              )}
            </div>
          </div>
          {mobile_windows_width > 430 && (
            <div className={styles.profitabilitySection}>
              <h5 className={styles.blockHeader}>Profitability</h5>
              <div className={styles.profitabilityContainer}>
                {createComputationProfitabilityTable(
                  profitabilityData?.[selectedCarrier],
                  selectedCarrier,
                  styles
                )}
              </div>
            </div>
          )}
        </div>
        {mobile_windows_width < 430 && (
          <div className={`sectionContent ${styles.sectionContent}`}>
            <div className={styles.profitabilitySection}>
              <h5 className={styles.blockHeader}>Profitability</h5>
              <div className={styles.profitabilityContainer}>
                {createComputationProfitabilityTable(
                  profitabilityData?.[selectedCarrier],
                  styles
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default React.memo(Computation);
