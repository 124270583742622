"use strict";
import React, { useState } from "react";
import UnderWriterTab from "./UnderWriterTab";
import SideTab from "../../newcomponent/common/SideTab";
import Header from "../common/Header";
import Loader from "../common/Loader";

let isMobile = window.innerWidth < 900;

const UnderWriterFlow = () => {
  const [dealMap, setDealMap] = useState({});

  const updateDealMap = (key, value) => {
    let dealMapCopy = JSON.parse(JSON.stringify(dealMap));
    dealMapCopy[key] = value;
    setDealMap(dealMapCopy);
  };

  return (
    <div className="dashboard-container">
      <div className="d-flex bg-light" style={{ borderBottomStyle: "groove" }}>
        <Header />
      </div>
      <div className={"submission-uw-dashboard"}>
        {isMobile ? <SideTab /> : " "}
        <h1
          style={{
            textAlign: "center",
            margin: "0px auto",
            fontFamily: "Montserrat",
          }}
          className="UWdashboard_title mt-3 mb-3"
        >
          Underwriter Dashboard
        </h1>
        <UnderWriterTab dealMap={dealMap} updateDealMap={updateDealMap} />
      </div>
      <Loader />
    </div>
  );
};

export default UnderWriterFlow;
