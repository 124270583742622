import React, { useEffect, useState } from "react";

export default function Comments(props) {
  const [comments, setComments] = useState("");

  useEffect(() => {
    if (comments !== props.value)
      setComments(props.value);
  }, [props.value])

  return (
    <textarea
      className="uw-comment"
      value={comments}
      onChange={e => setComments(e.target.value)}
      onBlur={e => props.callbackFunc(comments)}
    />

  );
}