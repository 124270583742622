import React, { Component } from "react";
import Tab from "./Tab";
import LoadingOverlay from "react-loading-overlay";

let isMobile = window.innerWidth < 1000;
class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.tabList[0].tabName,
    };
  }

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  render() {
    let {
      tabList,
      updateAcordSelectedState,
      carrierSpecific,
      loadingAccord,
    } = this.props;
    let { activeTab } = this.state;
    let indx = 0,
      hasTab = false;

    tabList.map((tb) => {
      if (tb.tabName === activeTab) {
        hasTab = true;
      }
    });

    if (!hasTab) {
      this.setState({ activeTab: tabList[0].tabName });
    }

    let dataToShow, state_to_show;
    console.log("++", tabList);
    tabList.map((currentTab) => {
      if (currentTab.tabName === activeTab) {
        dataToShow = currentTab.children;
        state_to_show = currentTab.state_shown;
      }
    });

    return (
      <div className="tabs" style={{ width: "100%", height: "100%" }}>
        {tabList.map((currentTab) => {
          return (
            <Tab
              activeTab={activeTab}
              tabName={currentTab.tabName}
              handleTabClick={this.handleTabClick}
              key={indx++}
              updateAcordSelectedState={updateAcordSelectedState}
              carrierSpecific={carrierSpecific}
              state_to_show={state_to_show}
              not_include_in_ncci={this.props.not_include_in_ncci}
            />
          );
        })}

        <LoadingOverlay active={loadingAccord[activeTab]} spinner>
          <div className={isMobile?"tab-content-responsive":"tab-content"}>{dataToShow}</div>
        </LoadingOverlay>
      </div>
    );
  }
}
export default Tabs;
