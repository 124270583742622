import { SelectOptions } from "./NewRatesModel";

export const separator = "-----";

interface IProgramCarrier {
  program: string;
  displayName: string;
  carrier: string;
}

export class ProgramCarrierDto implements IProgramCarrier {
  constructor(
    public program: string = "",
    public displayName: string = "",
    public carrier: string = ""
  ) {}

  public getOptions(_programCarrierDto: ProgramCarrierDto[]) {
    let _options: SelectOptions[] = [];
    _options.push(new SelectOptions("all", "Select All"));
    _programCarrierDto.forEach((item) => {
      _options.push(
        new SelectOptions(
          item.program + separator + item.carrier,
          item.displayName
        )
      );
    });

    _options.sort((a: any, b: any) => {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }
      return 0;
    });
    return _options;
  }
}

interface INewRates {
  program: string;
  carrier: string;
  manualRate: number;
  fundRate: number;
  netRate: number;
}

export class NewRatesDto implements INewRates {
  constructor(
    public program: string = "N/A",
    public carrier: string = "",
    public manualRate: number = 0,
    public fundRate: number = 0,
    public netRate: number = 0
  ) {}
}

interface IStates {
  State: string;
  Abbrev: string;
  Code: string;
}

export class StatesDto implements IStates {
  constructor(
    public State: string = "",
    public Abbrev: string = "",
    public Code: string = ""
  ) {}

  getOptions(_statesDto: StatesDto[]) {
    let _options: SelectOptions[] = [];
    _statesDto.forEach((item) => {
      _options.push(new SelectOptions(item.Code, item.Code));
    });
    return _options;
  }
}

export type EmodDataDto = {
  [key: string]: any;
};

export type ChildrenLocDto = {
  [key: string]: any;
};

interface IClassCodes {
  class_code: string;
  description: string;
}

export class ClassCodesDto implements IClassCodes {
  constructor(
    public class_code: string = "",
    public description: string = "",
    public eligibility?: object
  ) {}

  getOptions(_classCodesDto: ClassCodesDto[]) {
    let _options: SelectOptions[] = [];
    _classCodesDto.forEach((item) => {
      _options.push(
        new SelectOptions(
          item.class_code,
          item.class_code + ": " + item.description,
          item.eligibility
        )
      );
    });
    return _options;
  }
}

interface IPrograms {
  carrier: string;
  program: string;
  display_name: string;
}

export class ProgramsList implements IPrograms {
  constructor(
    public carrier: string = "",
    public program: string = "",
    public display_name: string = ""
  ) {}

  getPrograms(_selectedPrograms: SelectOptions[] | undefined) {
    let _programs: ProgramsList[] = [];
    _selectedPrograms &&
      _selectedPrograms.forEach((item) => {
        let values = item.value.split(separator);
        _programs.push(new ProgramsList(values[1], values[0], item.label));
      });
    return _programs;
  }
}

interface IGetRatesRequest {
  state: string;
  date: string;
  classCode: string;
  programList: IPrograms[];
  emodData: EmodDataDto;
}

export class GetsRatesRequestDto implements IGetRatesRequest {
  constructor(
    public state: string = "",
    public date: string = "",
    public classCode: string = "",
    public description: string = "",
    public programList: IPrograms[] = [], 
    public emodData: EmodDataDto = {}
  ) {}
}

export interface __debounce {
    (fun: Function): any;
  }
export interface __promise_options {
    (inputValue: string, callback: any): any;
  }