import React from "react";
import DashboardAdmin from "./DashboardAdmin";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { logout } from "../../utils/common";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
      program: "PEO",
      source: "Affinity",
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  render() {
    let { selected, program, source } = this.state;
    const tabsMap = {
      tab1: "Engaged with email campaign",
      tab2: "Did not engage with email campaign",
      // tab5: "PAYGO",
      tab3: "Sales Person Details",
      tab4: "Not Eligible",
    };

    let styleForCols = {
      flexDirection: "column",
      display: "flex",
      paddingRight: "2.5rem",
      paddingLeft: "0",
      marginBottom: "15px",
    };

    let styleForSelect = {
      fontSize: "16px",
      height: "35px",
    };

    let styleForLabel = {
      marginBottom: "0.1rem",
    };

    return (
      <div className="dashboard-container">
        <div className="d-flex" style={{ borderBottomStyle: "groove" }}>
          <Header />
          <div
            onClick={logout}
            className="d-flex align-items-center logout-div"
          >
            Logout
          </div>
        </div>
        <div className="tabs p-4">
          <div className="d-flex justify-content-center mb-3">
            <h2>
              <b>Prospect Dashboard</b>
            </h2>
          </div>
          <div className="d-flex">
            <div className="col-sm-12 col-lg-2" style={styleForCols}>
              <label for="program-dropdown" style={styleForLabel}>
                <b class="font-family-montserrat-semi-bold">Program</b>
              </label>
              <select
                value={program}
                id="program-dropdown"
                type="text"
                style={styleForSelect}
                onChange={(event) =>
                  this.setState({ program: event.target.value })
                }
                autoComplete="off"
              >
                <option key={2} value={"PEO"}>
                  {" "}
                  PEO{" "}
                </option>
                <option key={3} value={"PAYGO"}>
                  {" "}
                  PAYGO{" "}
                </option>
              </select>
            </div>
            <div className="col-sm-12 col-lg-2" style={styleForCols}>
              <label for="source-dropdown" style={styleForLabel}>
                <b class="font-family-montserrat-semi-bold">Source</b>
              </label>
              <select
                value={source}
                id="source-dropdown"
                type="text"
                style={styleForSelect}
                onChange={(event) =>
                  this.setState({ source: event.target.value })
                }
                autoComplete="off"
              >
                <option key={2} value={"Affinity"}>
                  {" "}
                  Affinity{" "}
                </option>
                <option key={3} value={"Hotschedules"}>
                  {" "}
                  Hotschedules{" "}
                </option>
              </select>
            </div>
          </div>
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{ fontSize: "16px" }}
          >
            {Object.keys(tabsMap).map((tab) => (
              <Nav.Item>
                <Nav.Link eventKey={tab} onClick={() => this.setSelected(tab)}>
                  {tabsMap[tab]}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          {Object.keys(tabsMap).map((tab) => (
            <div key={tab}>
              {
                <DashboardAdmin
                  tabName={tab}
                  selected={selected}
                  program={program}
                  source={source}
                />
              }
            </div>
          ))}
        </div>
        <Loader />
      </div>
    );
  }
}

export default Tabs;
