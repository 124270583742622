import React from "react";
import styles from "./PriceIndication.module.scss";
import { createPriceIndicationTable, submitQues } from "./utils/utils";

function PriceIndication({
  data,
  selectedCarrier,
  overridesMap,
  updateOverridesMap,
}) {
  const { classCodeTableData } = data || {};

  const recalculateQuote = async () => {
    try {
      await submitQues(overridesMap);
    } catch (error) {
      console.log(error);
    }
  };

  let fallbackCarrier = "";
  for (let carrier in classCodeTableData) {
    fallbackCarrier = carrier;
    break;
  }

  return (
    <section className={styles.indicationSection}>
      <div className="pink-header p-0 bg-white align-items-center">
        <div className="font-family-montserrat-bold headings">
          Price Indication
        </div>
      </div>
      <div className={`sectionContent ${styles.sectionContent}`}>
        <div className={`${styles.tableContainer} tableContainer`}>
          {createPriceIndicationTable(
            classCodeTableData?.[selectedCarrier || fallbackCarrier],
            overridesMap,
            updateOverridesMap,
            selectedCarrier,
            styles
          )}
        </div>
        <div className={styles.actionButtons}>
          <div className={`${styles.calculateSection}`}>
            <button
              className={`btn btn-warning btn-lg btn-warning-shadow btnSubmits`}
              disabled={!selectedCarrier}
              label={!selectedCarrier && "Select a carrier"}
              onClick={recalculateQuote}
            >
              Calculate
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(PriceIndication);
