import React, { Component } from "react";
import Navbar from "../component/common/Navbar";
import Loader from "../component/common/Loader";
import SideTab from "./common/SideTab";
import Header from "../component/common/Header";
import Popup from "../component/common/Popup";
import Stats2 from "../component/subcompo/sections-home/Stats2";
import QuotePageBanner from "../component/subcompo/sections-home/QuotePageBanner";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";
import axios from "axios";
import { awsUrl } from "../config";
const numeral = require("numeral");

class NewQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
    };
  }

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  updateCurrentFormStage = (currentFormStage, stageNumber) => {
    if (
      currentFormStage <= stageNumber &&
      this.state.currentFormStage !== currentFormStage
    ) {
      this.setState({ currentFormStage });
    }
  };

  updateMailCampaignUser = (mailCampaignUser) => {
    if (this.state.mailCampaignUser !== mailCampaignUser) {
      this.setState({ mailCampaignUser });
    }
  };

  render() {
    let { popupData, currentFormStage, mailCampaignUser } = this.state;
    return (
      <div className="newquote-mainbody container-fluid">
        {/* <Navbar /> */}
        <div
          className="d-flex justify-content-between pb-2"
          style={{ backgroundColor: "white" }}
        >
          <Header />
          {/* <Stats2 text2={true} /> */}
        </div>
        {(checkMailCampaignUser() || mailCampaignUser) &&
          currentFormStage === 1 && <QuotePageBanner />}
        <SideTab
          updatePopUpData={this.updatePopUpData}
          updateCurrentFormStage={this.updateCurrentFormStage}
          updateMailCampaignUser={this.updateMailCampaignUser}
          currentFormStage={currentFormStage}
        />
        <Loader />
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </div>
    );
  }
}

export default NewQuote;
