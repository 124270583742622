import React, { useState, useEffect } from "react";
import styles from "./Summary.module.scss";
import {
  createSummaryTable,
  formatToDollar,
  formatToPercent,
} from "./utils/utils";

function Summary({ data }) {
  const { totalWcData, adminData, sutaData } = data || {};
  let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
  let companyName = currProspect?.companyProfile?.companyName?.value

  return (
    <section className={styles.summarySection}>
      <div className="compName" style={{
        fontSize: "20px",
        fontWeight: "600",
        color: "#2c2e33",
        marginTop: "35px"
      }}><p style={{
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "0.5rem"
      }}>{companyName}
        </p></div>
      <div className="pink-header p-0 bg-white align-items-center">
        <div className="font-family-montserrat-bold headings">Summary</div>
      </div>
      <div className="sectionContent">
        <div className={styles.wcSection}>
          <h5 className={styles.blockHeader}>Total WC</h5>
          <div className={`${styles.wcTableContainer} tableContainer`}>
            {createSummaryTable(totalWcData, styles)}
          </div>
        </div>
        <div className={styles.sutaAdminSection}>
          <div className={styles.sutaAdminBlock}>
            <h5 className={styles.blockHeader}>Total Admin</h5>
            <div className={styles.blockContent}>
              <div>
                <b>Value ($):</b>
              </div>
              <div>
                {formatToDollar(adminData?.totalAdminValue, {
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
          <div className={styles.sutaAdminBlock}>
            <h5 className={styles.blockHeader}></h5>
            <div className={styles.blockContent}>
              <div>
                <b>Value (%):</b>
              </div>
              <div>{formatToPercent(adminData?.totalAdminPercent)}</div>
            </div>
          </div>
          <div className={styles.sutaAdminBlock}>
            <h5 className={styles.blockHeader}>Total SUTA</h5>
            <div className={styles.blockContent}>
              <div>
                <b>Value ($):</b>
              </div>
              <div>
                {formatToDollar(sutaData?.totalSuta, {
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Summary);
