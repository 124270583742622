import React from "react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = (props) => {
  let [isLoggedIn, setLoggedInStatus] = useState(true);

  useEffect(() => {
    let _isLoggedIn = true;
    try {
      if (props.allowedGroups) {
        let userGroups =
          props.sessionData.signInUserSession.accessToken.payload[
            "cognito:groups"
          ];
        let allowedAccess = props.allowedGroups.some((e) =>
          userGroups.includes(e)
        );

        if (!allowedAccess) {
          _isLoggedIn = false;
        }
      }
    } catch (error) {
      _isLoggedIn = false;
    }
    if (!_isLoggedIn) {
      setLoggedInStatus(false);
    }
  }, [props.allowedGroups]);

  return isLoggedIn ? props.children : <Navigate to={"/"} />;
};

export default PrivateRoute;
