import React from "react";

export default function StatusAndComments(props) {
  const updateValue = (status, comments) => {
    let {
      carrierStatusAndComments,
      updateCarrierStatusAndComments,
      carrier,
    } = props;

    if (!carrierStatusAndComments[carrier])
      carrierStatusAndComments[carrier] = {};

    if (status || comments) {
      if (status) carrierStatusAndComments[carrier].status = status;
      if (comments) carrierStatusAndComments[carrier].comments = comments;
    }

    updateCarrierStatusAndComments(carrierStatusAndComments);
  };

  let comm = props.carrierStatusAndComments?.[props.carrier]?.comments || "";
  let sts = props.carrierStatusAndComments?.[props.carrier]?.status || "";

  return (
    <div>
      <div className="m-1">
        <span>Status</span>
        <select
          className="uw-options"
          style={{ textAlign: "center" }}
          onChange={(e) => {
            updateValue(e.target.value, comm);
          }}
        >
          <option value="select">Select</option>
          <option value="bound">Bound</option>
          <option value="quoted">Quoted</option>
          <option value="closed">Closed</option>
          <option value="declined">Declined</option>
          <option value="uw_questions">UW Questions</option>
          <option value="submitted">Submitted</option>
        </select>
      </div>
      <div>
        <span>Comments</span>
        <textarea
          className="uw-comment"
          onBlur={(e) => updateValue(sts, e.target.value)}
        />
      </div>
    </div>
  );
}
