"use strict";
import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@mui/material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Search from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Check from "@mui/icons-material/Check";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

class PreUnderwriterFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      rowDataList: [],
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
    this.updateEligibilityDisplay();
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    var len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };

  setQuestions = (quesList) => {
    let response = {};
    for (let question of quesList) {
      response[question.name] = {
        ques: question.ques,
        response: question.response,
      };
    }

    return response;
  };

  onClickCompanyName = (row) => {
    let currProspect = {
      companyProfile: row.companyProfile,
      emodStatesData: row.emodStatesData,
      childrenLoc: row.payrollData,
    };
    sessionStorage.setItem("preUnderwriter", "true");
    sessionStorage.setItem("domain", row.domain);
    sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    if (
      row.pre_underwriter_status === "uw" ||
      row.pre_underwriter_status === "completed"
    ) {
      sessionStorage.setItem("formStage", "two");
    }
    if (row.quesList) {
      let quesList = this.setQuestions(row.quesList);
      sessionStorage.setItem("quesList", JSON.stringify(quesList));
    }
    if (row.amtrustQuesList) {
      let amtrustQuesList = this.setQuestions(row.amtrustQuesList);
      sessionStorage.setItem(
        "amtrustQuesList",
        JSON.stringify(amtrustQuesList)
      );
    }
    window.location.href = "/Quote";
  };

  setTableData = () => {
    let { rowDataList, isLoading } = JSON.parse(JSON.stringify(this.state));

    for (let rowData of rowDataList) {
      if (rowData.pre_underwriter_status === "completed") {
        rowData.status = "completed";
      } else {
        rowData.status = "in progress";
      }
    }

    // return (
    //   <MaterialTable
    //     isLoading={isLoading}
    //     icons={tableIcons}
    //     data={rowDataList}
    //     columns={[
    //       {
    //         title: "Company Name",
    //         field: "companyName",
    //         cellStyle: {
    //           padding: "6px 15px 6px 10px",
    //           width: "250px",
    //         },
    //         render: (rowData) => (
    //           <div
    //             id="company-name-link"
    //             onClick={() => this.onClickCompanyName(rowData)}
    //             style={{
    //               cursor: "pointer",
    //               color: "blue",
    //             }}
    //           >
    //             {this.camelCase(rowData.companyProfile.companyName.value)}
    //           </div>
    //         ),
    //       },
    //       {
    //         title: "Payroll",
    //         field: "payroll",
    //         render: (rowData) => numeral(rowData.payroll).format("$0,0"),
    //         cellStyle: {
    //           padding: "6px 15px 6px 10px",
    //           minWidth: "100px",
    //           width: "100px",
    //           maxWidth: "100px",
    //           wordBreak: "break-all",
    //         },
    //       },
    //       {
    //         title: "Effective Date",
    //         field: "effectiveDate",
    //         render: (rowData) =>
    //           moment(Number(rowData.timestamp)).format("M/D/YYYY"),
    //         cellStyle: {
    //           padding: "6px 15px 6px 10px",
    //         },
    //       },
    //       {
    //         title: "EMOD",
    //         field: "emod",
    //         headerStyle: {
    //           padding: 0,
    //         },
    //         cellStyle: {
    //           padding: 0,
    //         },
    //       },
    //       {
    //         title: "Status",
    //         field: "status",
    //         cellStyle: {
    //           wordBreak: "break-all",
    //           padding: "6px 0px",
    //           width: "150px",
    //         },
    //         headerStyle: {
    //           padding: "6px 0px",
    //         },
    //         render: (rowData) => (
    //           <Clamp lines={1}>{this.camelCase(rowData.status)}</Clamp>
    //         ),
    //       },
    //     ]}
    //     options={{
    //       paginationPosition: "both",
    //       padding: "dense",
    //       showTitle: false,
    //       thirdSortClick: false,
    //       pageSize: 25,
    //       pageSizeOptions: [25, 50, 75, 100],
    //       headerStyle: {
    //         fontSize: "12px",
    //         backgroundColor: "#003764",
    //         color: "#FFF",
    //         padding: "6px 15px 6px 10px",
    //       },
    //       rowStyle: {
    //         fontSize: "12px",
    //       },
    //       searchFieldStyle: {
    //         fontSize: "12px",
    //       },
    //     }}
    //     detailPanel={[
    //       {
    //         tooltip: "Client info",
    //         icon: () => <KeyboardArrowRightIcon />,
    //         render: (rowData) => {
    //           return (
    //             <div style={{ padding: "25px" }}>
    //               <TableContainer component={Paper}>
    //                 <Table size="small">
    //                   <TableHead
    //                     style={{
    //                       background: "#00000066",
    //                       color: "whitesmoke",
    //                     }}
    //                   >
    //                     <TableRow>
    //                       <TableCell>Contact Name</TableCell>
    //                       <TableCell>Phone Number</TableCell>
    //                       <TableCell>Email</TableCell>
    //                     </TableRow>
    //                   </TableHead>
    //                   <TableBody>
    //                     {rowData.email_specific ? (
    //                       Object.keys(rowData.email_specific).map(
    //                         (element, index) => {
    //                           return (
    //                             <TableRow key={index}>
    //                               <TableCell scope="row">
    //                                 {rowData.email_specific[element]
    //                                   .companyProfile.firstName.value +
    //                                   " " +
    //                                   rowData.email_specific[element]
    //                                     .companyProfile.lastName.value}
    //                               </TableCell>
    //                               <TableCell scope="row">
    //                                 {
    //                                   rowData.email_specific[element]
    //                                     .companyProfile.phoneNumber.value
    //                                 }
    //                               </TableCell>
    //                               <TableCell scope="row">
    //                                 {
    //                                   rowData.email_specific[element]
    //                                     .companyProfile.emailId.value
    //                                 }
    //                               </TableCell>
    //                             </TableRow>
    //                           );
    //                         }
    //                       )
    //                     ) : (
    //                       <TableRow key={0}>
    //                         <TableCell scope="row">
    //                           {rowData.contactName}
    //                         </TableCell>
    //                         <TableCell scope="row">
    //                           {rowData.phoneNumber}
    //                         </TableCell>
    //                         <TableCell scope="row">
    //                           {rowData.userEmail}
    //                         </TableCell>
    //                       </TableRow>
    //                     )}
    //                   </TableBody>
    //                 </Table>
    //               </TableContainer>
    //             </div>
    //           );
    //         },
    //       },
    //     ]}
    //     components={{
    //       Pagination: (props) => (
    //         <TablePagination
    //           {...props}
    //           labelRowsPerPage={
    //             <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
    //           }
    //           labelDisplayedRows={(row) => (
    //             <div style={{ fontSize: 12 }}>
    //               {props.labelDisplayedRows(row)}
    //             </div>
    //           )}
    //           SelectProps={{
    //             style: {
    //               fontSize: 12,
    //             },
    //           }}
    //         />
    //       ),
    //     }}
    //   />
    // );
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getPreUnderwriter")
      .then((res) => {
        let data = res.data;
        let payroll = 0,
          emod = 1;

        for (let row in data) {
          if (
            data[row].emodStatesData &&
            data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
              .rate1
          ) {
            emod =
              data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
                .rate1.value;
          }

          if (data[row].payrollData) {
            for (var state in data[row].payrollData) {
              for (var pay in data[row].payrollData[state].classCodesInfo) {
                var pyrll = numeral(
                  data[row].payrollData[state].classCodesInfo[pay].payroll.value
                ).value();

                payroll += parseInt(pyrll);
              }
            }
          }
          data[row].payroll = payroll;
          data[row].emod = emod;
        }

        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { selected, tabName } = this.props;
    return (
      <div className={"pre-uw-dashboard"} style={{ width: "100%" }}>
        <div>{this.setTableData()}</div>
      </div>
    );
  }
}

export default PreUnderwriterFlow;
