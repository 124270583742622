import React, { Component } from "react";
import { clearErr } from "../../utils/common";
var stateCity = require("../../utils/state_city.json");

class StateList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   const { updateLocationInfo } = this.props;
  //   let formStage = sessionStorage.getItem("formStage");
  //   if (formStage === undefined || formStage === null) {
  //     updateLocationInfo("TX", 0, "state");
  //   }
  // }

  componentDidUpdate() {
    const { resetClassCodeDescriptionData, addressBlockNo } = this.props;
    if (
      JSON.stringify(this.state.locationInfo) !==
      JSON.stringify(this.props.locationInfo)
    ) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
    if (JSON.stringify(this.props.state) !== JSON.stringify(this.state.state)) {
      this.setState({
        state: this.props.state,
      });
      // resetClassCodeDescriptionData(addressBlockNo);
    }
  }

  render() {
    const { updateLocationInfo, resetClassCodeDescriptionData } = this.props;
    const { locationInfo } = this.state;
    const number = locationInfo ? locationInfo.number : null;
    // console.log("locationInfo: ", locationInfo);

    const stateList = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item of stateCity) {
      if (locationInfo && item.Code === locationInfo.state.value) {
        stateList.push(
          <option key={item.Code} value={item.Code} selected>
            {item.Code}
          </option>
        );
      } else {
        stateList.push(
          <option key={item.Code} value={item.Code}>
            {item.Code}
          </option>
        );
      }
    }
    return (
      <select
        name="stateCodes"
        onChange={(event) => {
          updateLocationInfo(event.target.value, number, "state");
          resetClassCodeDescriptionData(number);
        }}
        style={
          locationInfo && locationInfo.state.error ? {outline:"none", borderColor: "red" } : {}
        }
      >
        {stateList}
      </select>
    );
  }
}
export default StateList;
