import React from "react";
import SmallLoader from "../../common/SmallLoader";
import { createPricingSummaryTable } from "../sections-quote/pricing-tool/utils/utils";

const PricingTableSummary = ({
  styles,
  sutaTypeMap,
  data,
  pricingTablesummaryDataLoader,
  selectedCarrier,
  companyName,
  proposalNumber,
}) => {
  return (
    <>
      <div>
        <div className="d-flex justify-content-between mt-5 mb-3">
          <h5 className="fw-bold">Pricing Summary</h5>
          {!pricingTablesummaryDataLoader && (
            <div className="d-flex">
              <p className="pr-3">
                <span>
                  <b>Pricing Indication created for :</b> {companyName}
                </span>
              </p>
              <p>&nbsp;&nbsp;&nbsp;</p>
              <p className="pl-3">
                <span>
                  <b>Proposal # :</b> {proposalNumber}
                </span>
              </p>
            </div>
          )}
        </div>

        {!pricingTablesummaryDataLoader && (
          <>
            <div className={`${styles.tableContainer} tableContainer`}>
              {createPricingSummaryTable(data?.[selectedCarrier], styles)}
            </div>

            <div className="mt-5 mb-5">
              {sutaTypeMap?.peoStates?.length > 0 && (
                <p
                  className="fw-bold d-flex justify-content-center"
                  style={{ fontSize: "12px" }}
                >
                  *** {sutaTypeMap.peoStates.join(", ")} - Total Burden includes
                  Workers Compensation and Risk Management Administration (Bill
                  Rate) plus FICA/Medicare and FUTA ***
                </p>
              )}
              {sutaTypeMap?.clientStates?.length > 0 && (
                <p
                  className="fw-bold d-flex justify-content-center"
                  style={{ fontSize: "12px" }}
                >
                  *** {sutaTypeMap.clientStates.join(", ")} - States SUTA will
                  be calculated, reported and paid with clients SUTA
                  information, total burden rate above does not include SUTA ***
                </p>
              )}
            </div>
          </>
        )}
        {pricingTablesummaryDataLoader && (
          <div className="d-flex justify-content-center">
            <SmallLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(PricingTableSummary);
