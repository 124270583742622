import { NumericFormat } from "react-number-format";
import { getBrokerOptions } from "./utils";
const removeIcon = require("../../../../../images/delete4.png");

function BrokerDetials({
  brokerModifiersData,
  allBrokerList,
  modifyBrokerList,
  index,
  styles,
}) {
  const getDefaultBrokerRate = () => {
    let rate = 0;

    try {
      if (brokerModifiersData.name) {
        const defaultBroker = allBrokerList.find(
          (element) => element.id === brokerModifiersData.name
        );
        rate = defaultBroker?.rate || 0;
      }
    } catch (error) {
      console.log(error);
      rate = 0;
    } finally {
      return rate;
    }
  };

  return (
    <div className={`${styles.blockContent} w-100`}>
      <div>Select Broker</div>
      <select
        name="brokers"
        id="brokers"
        className={`${styles.carrierValue} w-auto`}
        onChange={(event) => {
          modifyBrokerList({
            operation: "update",
            index,
            key: "name",
            value: event.target.value,
          });
        }}
      >
        {getBrokerOptions(brokerModifiersData, allBrokerList)};
      </select>
      <NumericFormat
        value={
          brokerModifiersData?.value === null
            ? getDefaultBrokerRate()
            : brokerModifiersData.value
        }
        decimalScale={2}
        fixedDecimalScale
        suffix={"%"}
        allowNegative={false}
        onValueChange={(values, sourceInfo) => {
          modifyBrokerList({
            operation: "update",
            index,
            key: "value",
            value: values.floatValue,
          });
        }}
        placeholder="0.00%"
      />
      <label class="checkbox-inline">
        <input
          type="checkbox"
          value="payroll"
          checked={brokerModifiersData.type === "payroll"}
          onClick={() => {
            modifyBrokerList({
              operation: "update",
              index,
              key: "type",
              value: "payroll",
            });
          }}
        />
        Payroll
      </label>
      <label class="checkbox-inline">
        <input
          type="checkbox"
          value="premium"
          checked={brokerModifiersData.type === "premium"}
          onClick={() => {
            modifyBrokerList({
              operation: "update",
              index,
              key: "type",
              value: "premium",
            });
          }}
        />
        Premium
      </label>
      {index !== 0 && (
        <div
          className={styles.removeBrokerSection}
          onClick={() => modifyBrokerList({ index, operation: "remove" })}
        >
          <img src={removeIcon} alt="remove broker" />
        </div>
      )}
    </div>
  );
}

export default BrokerDetials;
