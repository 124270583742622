import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@mui/material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Search from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Check from "@mui/icons-material/Check";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ThemeProvider, createTheme } from "@mui/material";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      program: "PEO",
      source: "Affinity",
    };
  }

  fetchDashboardData = () => {
    if (this.props.tabName === "tab3") {
      // this.updateSalesDisplay();
    } else if (this.props.tabName === "tab4") {
      // this.updateEligibilityDisplay();
    } else if (this.props.tabName === "tab5") {
      this.updatePaygoData();
    } else {
      this.updateDisplay();
    }
  };

  componentDidMount() {
    this.fetchDashboardData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.program !== prevProps.program) {
      this.setState({ program: this.props.program });
    }
    if (this.props.source !== prevProps.source) {
      this.setState({ source: this.props.source });
    }
  }

  updateComment = (e, rowData) => {
    let { rowDataList, program, source } = JSON.parse(
      JSON.stringify(this.state)
    );
    let index = rowData.tableData.id;
    let requestBody = {};
    requestBody.domain =
      rowDataList[program] && rowDataList[program][source]
        ? rowDataList[program][source][index].domain
        : null;
    requestBody.comment = e.target.value;
    requestBody.tableName = this.props.tabName;
    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateDiscountData = (rowData) => {
    let requestBody = {
      user_email_id: rowData.userEmail,
      uuid_carrier: rowData.uuid_carrier,
      discountData: rowData.discountData,
    };
    axios
      .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
      .then((res) => {})
      .catch((err) => console.log("error: ", err));
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    var len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };

  setTableData = () => {
    let { rowDataList, isLoading, discountDataObject, program, source } =
      JSON.parse(JSON.stringify(this.state));
    let { tabName } = this.props;

    rowDataList =
      tabName === "tab1" || tabName === "tab2"
        ? rowDataList && rowDataList[program] && rowDataList[program][source]
          ? rowDataList[program][source]
          : []
        : rowDataList;

    let columns = {
      tab1: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          defaultSort: "desc",
          render: (rowData) => {
            let visitDate = moment(Number(rowData.visitDate))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = visitDate.split("-")[0];
            let time = visitDate.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
            wordBreak: "break-word",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.companyName)}</Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "payroll",
          render: (rowData) => numeral(rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 0",
            minWidth: "80px",
            width: "80px",
            maxWidth: "80px",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          render: (rowData) =>
            moment(Number(rowData.effectiveDate)).format("M/D/YYYY"),
          cellStyle: {
            padding: "6px 0",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "EMOD",
          field: "emod",
          cellStyle: {
            padding: "6px 0",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          cellStyle: {
            padding: "6px 0",
            minWidth: "20px",
            width: "20px",
            maxWidth: "20px",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 10px",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.latestCampaign}</Clamp>
          ),
        },
        {
          title: "Steps",
          field: "step",
          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
        },
        {
          title: "Comments",
          field: "comment",
          render: (rowData) => {
            let index = rowData.tableData.id;
            let { rowDataList } = JSON.parse(JSON.stringify(this.state));
            return (
              <textarea
                value={
                  rowDataList[this.state.program] &&
                  rowDataList[this.state.program][this.state.source] &&
                  rowDataList[this.state.program][this.state.source][index]
                    ? rowDataList[this.state.program][this.state.source][index]
                        .comment || ""
                    : ""
                }
                onChange={(e) => {
                  rowDataList[this.state.program][this.state.source][
                    index
                  ].comment = e.target.value;
                  this.setState({ rowDataList });
                }}
                onBlur={(e) => this.updateComment(e, rowData)}
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "180px",
            width: "180px",
            maxWidth: "180px",
          },
        },
      ],
      tab2: [
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
            width: "140px",
            maxidth: "140px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.companyName)}</Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "payroll",
          render: (rowData) => numeral(rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
            wordBreak: "break-all",
          },
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          render: (rowData) =>
            moment(Number(rowData.effectiveDate)).format("M/D/YYYY"),
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "EMOD",
          field: "emod",
          headerStyle: {
            padding: 0,
          },
          cellStyle: {
            padding: 0,
          },
        },
        {
          title: "Under Writer Email",
          field: "uwEmail",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0px",
            width: "150px",
          },
          headerStyle: {
            padding: "6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>
              {rowData.uwEmail ? this.camelCase(rowData.uwEmail) : "-"}
            </Clamp>
          ),
        },
        {
          title: "Comments",
          field: "comment",
          render: (rowData) => {
            let index = rowData.tableData.id;
            let { rowDataList } = JSON.parse(JSON.stringify(this.state));
            return (
              <textarea
                value={
                  rowDataList[this.state.program] &&
                  rowDataList[this.state.program][this.state.source] &&
                  rowDataList[this.state.program][this.state.source][index]
                    ? rowDataList[this.state.program][this.state.source][index]
                        .comment || ""
                    : ""
                }
                onChange={(e) => {
                  rowDataList[this.state.program][this.state.source][
                    index
                  ].comment = e.target.value;
                  this.setState({ rowDataList });
                }}
                onBlur={(e) => this.updateComment(e, rowData)}
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            minWidth: "300px",
            width: "300px",
            maxWidth: "300px",
            padding: "6px 15px 6px 10px",
          },
        },
      ],
      tab3: [
        {
          title: "Sales Person",
          field: "salesperson_email",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 10px",
            minWidth: "160px",
            width: "160px",
            maxWidth: "160px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.salesperson_email.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          defaultSort: "desc",
          render: (rowData) => {
            let visitDate = moment(Number(rowData.visitDate))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = visitDate.split("-")[0];
            let time = visitDate.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                <div>
                  {/* <Link
                    to={"/Quote" + "?" + "sales=" + rowData.userEmail}
                    target="_blank"
                  > */}
                  {this.camelCase(rowData.companyName)}
                  {/* </Link> */}
                </div>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Contact Name",
          field: "contactName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.contactName)}</Clamp>
          ),
        },
        {
          title: "Phone Number",
          field: "phoneNumber",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          render: (rowData) => numeral(rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 0",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          render: (rowData) =>
            moment(Number(rowData.effectiveDate)).format("M/D/YYYY"),
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Email",
          field: "userEmail",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            minWidth: "160px",
            width: "160px",
            maxWidth: "160px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.userEmail.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "Discount",
          field: "discount",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            minWidth: "140px",
            width: "140px",
            maxWidth: "140px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <div className="input-group input-group-sm discount-body">
              <BootstrapSwitchButton
                checked={rowData.discountData.type === "percent"}
                id={rowData.uuid}
                onChange={(e) => {
                  let id = rowData.tableData.id;
                  let switchState = rowDataList[id].discountData.type;
                  let va = rowDataList[id].discountData.value;
                  let errFlg = false,
                    errMsg,
                    type;
                  if (switchState === "percent") {
                    type = "dollar";
                    if (va !== "")
                      if (Number(va) < 0) {
                        errMsg = "Please enter a positive value";
                        errFlg = true;
                      }
                  } else {
                    type = "percent";
                    if (va !== "")
                      if (Number(va) < 0) {
                        errMsg = "Please enter a positive value";
                        errFlg = true;
                      } else if (Number(va) > 100) {
                        errMsg = "Percent value cannot be greater than 100";
                        errFlg = true;
                      }
                  }
                  if (!errFlg) {
                    rowDataList[id].discountData.type = type;
                    this.updateDiscountData(rowDataList[id]);
                  } else {
                    this.forceUpdate();
                    alert(errMsg);
                  }
                }}
                size="sm"
                onstyle="outline-dark"
                offstyle="outline-dark"
                onlabel="%"
                offlabel="$"
              />
              <input
                defaultValue={
                  rowDataList[rowData.tableData.id].discountData.value
                }
                style={
                  rowDataList[rowData.tableData.id].inputErrr
                    ? { border: "1px solid red" }
                    : {}
                }
                type="number"
                className="form-control"
                placeholder="Discount"
                onBlur={(e) => {
                  let val = e.target.value;
                  let id = rowData.tableData.id;
                  let switchState = rowDataList[id].discountData.type;
                  let errFlg = false;
                  if (switchState === "percent") {
                    rowDataList[id].discountData.type = "percent";
                    if (e.target.value !== "")
                      if (Number(e.target.value) < 0) {
                        alert("Please enter a positive value");
                        errFlg = true;
                      } else if (Number(e.target.value) > 100) {
                        alert("Percent value cannot be greater than 100");
                        errFlg = true;
                      }
                  } else {
                    rowDataList[id].discountData.type = "dollar";
                    if (e.target.value !== "")
                      if (Number(e.target.value) < 0) {
                        alert("Please enter a positive value");
                        errFlg = true;
                      }
                  }
                  rowDataList[id].discountData.value = val;
                  if (!errFlg) {
                    this.updateDiscountData(rowDataList[id]);
                  }
                }}
              />
            </div>
          ),
        },
        {
          title: "Status",
          field: "step",
          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };

            if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Docs Pending") {
              style.backgroundColor = "#eb6631a7";
            } else if (rowData.step === "Submitted") {
              style.backgroundColor = "#ffd737ac";
            } else {
              style.backgroundColor = "#FFF";
            }

            return style;
          },
        },
      ],
      tab4: [
        {
          title: "Email",
          field: "email",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.email.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "Visit Date (ET)",
          field: "timestamp",
          defaultSort: "desc",
          render: (rowData) => {
            let visitDate = moment(Number(rowData.timestamp))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = visitDate.split("-")[0];
            let time = visitDate.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "150px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(
                rowData.currProspect.companyProfile.companyName.value
              )}
            </Clamp>
          ),
        },
        {
          title: "FEIN",
          field: "fein",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {rowData.currProspect.companyProfile.fein
                ? this.formatFein(
                    rowData.currProspect.companyProfile.fein.value
                  )
                : "-"}
            </Clamp>
          ),
        },
        {
          title: "Phone number",
          field: "phoneNumber",
          render: (rowData) =>
            rowData.currProspect.companyProfile.phoneNumber.value,
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-all",
            width: "150px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Primary Contact",
          field: "contact",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            width: "200px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>
              {this.camelCase(
                rowData.currProspect.companyProfile.firstName.value
              )}
            </Clamp>
          ),
        },
        {
          title: "Status",
          field: "status",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0px",
            width: "150px",
          },
          headerStyle: {
            padding: "6px 0px",
          },
          render: (rowData) => <Clamp lines={1}>{rowData.origin}</Clamp>,
        },
      ],
      tab5: [
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
            width: "140px",
            maxidth: "140px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData.companyProfile.companyName.value)}
            </Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "payroll",
          render: (rowData) => numeral(rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
            wordBreak: "break-all",
          },
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          render: (rowData) =>
            moment(Number(rowData.timestamp)).format("M/D/YYYY"),
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "EMOD",
          field: "emod",
          headerStyle: {
            padding: 0,
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "100px",
            width: "100px",
            maxWidth: "100px",
            wordBreak: "break-all",
          },
        },
        {
          title: "Under Writer Email",
          field: "uwEmail",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0px",
            width: "150px",
          },
          headerStyle: {
            padding: "6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{this.camelCase(rowData.uwEmail)}</Clamp>
          ),
        },
        {
          title: "Comments",
          field: "comment",
          render: (rowData) => (
            <textarea
              defaultValue={rowData.comment || ""}
              onBlur={(e) => this.updateComment(e, rowData)}
              rows="2"
              style={{ width: "100%" }}
            ></textarea>
          ),
          cellStyle: {
            minWidth: "300px",
            width: "300px",
            maxWidth: "300px",
            padding: "6px 15px 6px 10px",
          },
        },
      ],
    };

    const defaultMaterialTheme = createTheme();

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          isLoading={isLoading}
          icons={tableIcons}
          data={rowDataList}
          columns={columns[tabName]}
          options={{
            paginationPosition: "both",
            padding: "dense",
            showTitle: false,
            thirdSortClick: false,
            pageSize: 25,
            pageSizeOptions: [25, 50, 75, 100],
            headerStyle: {
              fontSize: "12px",
              backgroundColor: "#003764",
              color: "#FFF",
              padding: "6px 15px 6px 10px",
            },
            rowStyle: {
              fontSize: "12px",
            },
            searchFieldStyle: {
              fontSize: "12px",
            },
          }}
          detailPanel={
            tabName === "tab4"
              ? [
                  {
                    tooltip: "Class code info",
                    icon: () => <KeyboardArrowRightIcon />,
                    render: (rowData) => {
                      return (
                        <div style={{ padding: "25px" }}>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead
                                style={{
                                  background: "#00000066",
                                  color: "whitesmoke",
                                }}
                              >
                                <TableRow>
                                  <TableCell>Class Code</TableCell>
                                  <TableCell>Description</TableCell>
                                  <TableCell>NAICS</TableCell>
                                  <TableCell>Payroll</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowData.excludedList.map((element, indx) => {
                                  return (
                                    <TableRow key={indx}>
                                      <TableCell scope="row">
                                        {element.class_code}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {element.description}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {element.naics}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {element.payroll}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      );
                    },
                  },
                ]
              : tabName === "tab2"
              ? [
                  {
                    tooltip: "Client info",
                    icon: () => <KeyboardArrowRightIcon />,
                    render: (rowData) => {
                      return (
                        <div style={{ padding: "25px" }}>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead
                                style={{
                                  background: "#00000066",
                                  color: "whitesmoke",
                                }}
                              >
                                <TableRow>
                                  <TableCell>Contact Name</TableCell>
                                  <TableCell>Phone Number</TableCell>
                                  <TableCell>Email</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowData.email_specific ? (
                                  Object.values(rowData.email_specific).map(
                                    (element, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell scope="row">
                                            {
                                              element.companyProfile.firstName
                                                .value
                                            }
                                          </TableCell>
                                          <TableCell scope="row">
                                            {
                                              element.companyProfile.phoneNumber
                                                .value
                                            }
                                          </TableCell>
                                          <TableCell scope="row">
                                            {
                                              element.companyProfile.emailId
                                                .value
                                            }
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )
                                ) : (
                                  <TableRow key={0}>
                                    <TableCell scope="row">
                                      {rowData.contactName}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.phoneNumber
                                        ? rowData.phoneNumber.length == 10
                                          ? rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{4})/,
                                              "($1) $2-$3"
                                            )
                                          : rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{3})/,
                                              "($1) $2-$3"
                                            )
                                        : "-"}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.userEmail}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      );
                    },
                  },
                ]
              : tabName === "tab1"
              ? [
                  {
                    tooltip: "Client info",
                    icon: () => <KeyboardArrowRightIcon />,
                    render: (rowData) => {
                      return (
                        <div style={{ padding: "25px" }}>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead
                                style={{
                                  background: "#00000066",
                                  color: "whitesmoke",
                                }}
                              >
                                <TableRow>
                                  <TableCell>Contact Name</TableCell>
                                  <TableCell>Phone Number</TableCell>
                                  <TableCell>Email</TableCell>
                                  <TableCell>Visit Count</TableCell>
                                  <TableCell>Campaign Name</TableCell>
                                  <TableCell>Steps</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowData.email_specific ? (
                                  Object.values(rowData.email_specific).map(
                                    (element, index) => {
                                      console.dir(element, { depth: null });
                                      if (element.companyProfile) {
                                        let step;
                                        if (
                                          Number(
                                            element.proposalGeneratedCount
                                          ) > 0
                                        ) {
                                          step = "Proposal Generated";
                                        } else if (element.quote_status) {
                                          if (
                                            element.quote_status === "uwportal"
                                          ) {
                                            step = "UW Portal";
                                          } else if (
                                            element.quote_status ===
                                            "proceed_uw"
                                          ) {
                                            step = "Approved";
                                          } else if (
                                            element.quote_status === "reject"
                                          ) {
                                            step = "Rejected";
                                          }
                                        } else if (
                                          Number(element.quoteGeneratedCount) >
                                          0
                                        ) {
                                          step = "Quote Generated";
                                        } else if (
                                          Number(element.visitCount) > 0
                                        ) {
                                          if (element.formStage) {
                                            if (element.formStage === "two") {
                                              if (element.nonEligibilityList) {
                                                step = "Not Eligible";
                                              } else {
                                                step = "UW Questions";
                                              }
                                            } else if (
                                              element.formStage === "one"
                                            ) {
                                              step = "Company Profile";
                                            }
                                          } else {
                                            step = "Landing Page";
                                          }
                                        } else {
                                          step = "-";
                                        }
                                        return (
                                          <TableRow key={index}>
                                            <TableCell scope="row">
                                              {
                                                element.companyProfile.firstName
                                                  .value
                                              }
                                            </TableCell>
                                            <TableCell scope="row">
                                              {
                                                element.companyProfile
                                                  .phoneNumber.value
                                              }
                                            </TableCell>
                                            <TableCell scope="row">
                                              {
                                                element.companyProfile.emailId
                                                  .value
                                              }
                                            </TableCell>
                                            <TableCell scope="row">
                                              {element.visitCount || 0}
                                            </TableCell>
                                            <TableCell scope="row">
                                              {element.campaignMap
                                                ? element.campaignMap[
                                                    Object.keys(
                                                      element.campaignMap
                                                    )
                                                      .sort()
                                                      .reverse()[0]
                                                  ] || "-"
                                                : "-"}
                                            </TableCell>
                                            <TableCell scope="row">
                                              {step}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    }
                                  )
                                ) : (
                                  <TableRow key={0}>
                                    <TableCell scope="row">
                                      {rowData.contactName}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.phoneNumber
                                        ? rowData.phoneNumber.length == 10
                                          ? rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{4})/,
                                              "($1) $2-$3"
                                            )
                                          : rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{3})/,
                                              "($1) $2-$3"
                                            )
                                        : "-"}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.userEmail}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.visitCount}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.latestCampaign}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.step}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      );
                    },
                  },
                ]
              : tabName === "tab5"
              ? [
                  {
                    tooltip: "Client info",
                    icon: () => <KeyboardArrowRightIcon />,
                    render: (rowData) => {
                      return (
                        <div style={{ padding: "25px" }}>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead
                                style={{
                                  background: "#00000066",
                                  color: "whitesmoke",
                                }}
                              >
                                <TableRow>
                                  <TableCell>Contact Name</TableCell>
                                  <TableCell>Phone Number</TableCell>
                                  <TableCell>Email</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowData.email_specific ? (
                                  Object.keys(rowData.email_specific).map(
                                    (element, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell scope="row">
                                            {rowData.email_specific[element]
                                              .companyProfile.firstName.value +
                                              " " +
                                              rowData.email_specific[element]
                                                .companyProfile.lastName.value}
                                          </TableCell>
                                          <TableCell scope="row">
                                            {
                                              rowData.email_specific[element]
                                                .companyProfile.phoneNumber
                                                .value
                                            }
                                          </TableCell>
                                          <TableCell scope="row">
                                            {
                                              rowData.email_specific[element]
                                                .companyProfile.emailId.value
                                            }
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )
                                ) : (
                                  <TableRow key={0}>
                                    <TableCell scope="row">
                                      {rowData.contactName}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.phoneNumber
                                        ? rowData.phoneNumber.length == 10
                                          ? rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{4})/,
                                              "($1) $2-$3"
                                            )
                                          : rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{3})/,
                                              "($1) $2-$3"
                                            )
                                        : "-"}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {rowData.userEmail}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      );
                    },
                  },
                ]
              : false
          }
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                labelRowsPerPage={
                  <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                }
                labelDisplayedRows={(row) => (
                  <div style={{ fontSize: 12 }}>
                    {props.labelDisplayedRows(row)}
                  </div>
                )}
                SelectProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            ),
          }}
        />
      </ThemeProvider>
    );
  };

  updateSalesDisplay = async () => {
    axios
      .get(awsUrl2 + "/api/getSalesDashboardDataAdmin")
      .then((res) => {
        let userDataList = [];
        let rowData;
        let rowDataList = {
          PEO: {
            Affinity: [],
            Hotschedules: [],
          },
          PAYGO: {
            Affinity: [],
            Hotschedules: [],
          },
        };
        let discountDataObject = {};
        let step;

        userDataList = res.data;

        for (let userDataRow in userDataList) {
          let userData = userDataList[userDataRow];

          let firstFilter = userData.onlyPaygo ? "PAYGO" : "PEO";
          let secondFilter =
            userData.client_source === "hotschedules"
              ? "Hotschedules"
              : "Affinity";

          let payroll = 0;
          for (let stateIndex in userData.prospect_addresses) {
            for (let classCodeIndex in userData.prospect_addresses[stateIndex]
              .classCodes) {
              payroll = numeral(payroll)
                .add(
                  userData.prospect_addresses[stateIndex].classCodes[
                    classCodeIndex
                  ].payroll
                )
                .value();
            }
          }

          if (userData.quote_status === "reject") {
            step = "Rejected";
          } else if (userData.quote_status === "proceed_uw") {
            step = "Approved";
          } else if (
            userData.doc_status !== "not_uploaded" ||
            userData.nolossGenerated
          ) {
            step = "Submitted";
          } else if (
            userData.doc_status === "not_uploaded" ||
            !userData.doc_status
          ) {
            step = "Docs Pending";
          } else {
            step = "-";
          }

          rowData = {
            visitDate: userData.date,
            userEmail: userData.user_email_id,
            uuid: userData.uuid,
            phoneNumber: userData.phone_number,
            indicationCost: userData.carrier_location_data.total,
            effectiveDate: moment(userData.effective_date).format("x"),
            contactName: userData.contact_person,
            companyName: userData.company_name,
            domain: userData.domain,
            quote_status: userData.quote_status,
            doc_status: userData.doc_status,
            salesperson_email: userData.salesperson_email,
            discountData: userData.discountData || {
              type: "percent",
              value: "",
            },
            payroll,
            step,
            uuid_carrier: userData.uuid_carrier,
            inputErrr: false,
          };

          rowDataList[firstFilter][secondFilter].push(rowData);

          discountDataObject[
            `${userData.user_email_id}-${userData.uuid_carrier}`
          ] = userData.discountData || {
            type: "percent",
            value: "",
          };
        }

        this.setState({ rowDataList, discountDataObject });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getNonEligibleData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updatePaygoData = () => {
    axios
      .post(awsUrl2 + "/api/peoCampaignData/getAll", {})
      .then((res) => {
        let rowData;
        let rowDataList = [];

        let data = res.data;

        let payroll = 0,
          emod = 1;

        for (let row in data) {
          if (
            data[row].emodStatesData &&
            data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
              .rate1
          ) {
            emod =
              data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
                .rate1.value;
          }

          if (data[row].payrollData) {
            for (var state in data[row].payrollData) {
              for (var pay in data[row].payrollData[state].classCodesInfo) {
                var pyrll = numeral(
                  data[row].payrollData[state].classCodesInfo[pay].payroll.value
                ).value();

                payroll += parseInt(pyrll);
              }
            }
          }
          data[row].payroll = payroll;
          data[row].emod = emod;
        }

        this.setState({ rowDataList: data });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplay = () => {
    let { tabName } = this.props;
    let requestBody = {
      tab: tabName,
    };
    axios
      .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
      .then((res) => {
        let userDataList = [];
        let tempObj;
        let rowData;
        let rowDataList = {
          PEO: {
            Affinity: [],
            Hotschedules: [],
          },
          PAYGO: {
            Affinity: [],
            Hotschedules: [],
          },
        };

        let dataFromApi = res.data.data;
        for (let key in dataFromApi) {
          tempObj = {};
          for (let dataKey in dataFromApi[key]) {
            tempObj[dataKey] = dataFromApi[key][dataKey];
          }
          userDataList.push(tempObj);
        }

        for (let userData of userDataList) {
          let quote_status;
          let step;
          let excluded_status;

          let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
          let secondFilter =
            userData.client_source === "Hotschedules"
              ? "Hotschedules"
              : "Affinity";

          for (let email in userData.email_specific) {
            let db_quote_status = userData.email_specific[email].quote_status;
            let db_excluded = userData.email_specific[email].nonEligibilityList;
            if (db_excluded && db_excluded.length > 0) {
              excluded_status = true;
            }

            if (
              (!quote_status && db_quote_status) ||
              (quote_status === "reject" && db_quote_status) ||
              db_quote_status === "proceed_uw"
            ) {
              quote_status = db_quote_status;
            }
          }

          if (Number(userData.proposalGeneratedCount) > 0) {
            step = "Proposal Generated";
          } else if (quote_status) {
            if (quote_status === "uwportal") {
              step = "UW Portal";
            } else if (quote_status === "proceed_uw") {
              step = "Approved";
            } else if (quote_status === "reject") {
              step = "Rejected";
            }
          } else if (Number(userData.quoteGeneratedCount) > 0) {
            step = "Quote Generated";
          } else if (Number(userData.visitCount) > 0) {
            if (userData.formStage) {
              if (userData.formStage === "one") {
                step = "Company Profile";
              } else {
                if (excluded_status) {
                  step = "Not Eligible";
                } else {
                  step = "UW Questions";
                }
              }
            } else {
              step = "Landing Page";
            }
          } else {
            step = "-";
          }
          rowData = {
            visitDate: userData.timestamp,
            userEmail: userData.email,
            quoteGeneratedCount: userData.quoteGeneratedCount,
            proposalGeneratedCount: userData.proposalGeneratedCount,
            uuid: userData.uuid,
            phoneNumber: userData.phoneNumber,
            payroll: userData.payroll,
            indicationCost: userData.indicationCost,
            emod: userData.emod,
            uwEmail: userData.uwEmail,
            effectiveDate: moment(userData.effectiveDate).format("x"),
            contactName: userData.contactName,
            companyName: userData.companyName,
            comment: userData.comment || "",
            domain: userData.domain,
            visitCount: userData.visitCount,
            step,
            latestCampaign: userData.latestCampaign,
            email_specific: userData.email_specific,
          };
          rowDataList[firstFilter][secondFilter].push(rowData);
        }

        this.setState({ rowDataList });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { selected, tabName } = this.props;
    return (
      <div
        className={"admin-dashboard " + `${tabName}`}
        style={
          tabName !== selected ? { display: "none", fontSize: "12px" } : {}
        }
      >
        <div>{this.setTableData()}</div>
      </div>
    );
  }
}

export default DashboardAdmin;
