import React from "react";
import Header from "../common/Header";
import DropZones from "../common/DropZones3";
import SideTab from "../../newcomponent/common/SideTab";
let isMobile = window.innerWidth < 900;
class DataUpload extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="position-relative">
          {isMobile ? <SideTab /> : " "}
          <div className="upload-heading my-0 mx-auto text-center mt-5 mb-5">
            <h1 className="m-0">Upload Rater Data</h1>
          </div>
          <DropZones />
        </div>
      </>
    );
  }
}
export default DataUpload;
