import store from "../redux/store/index";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");
export function setProspectDetails() {
  var companyDetails = store.getState().prospectDetails;
  if (companyDetails !== null) {
    $("#companyName").val(companyDetails.companyName);
    $("#description").val(companyDetails.description);
    $("#contactPerson").val(companyDetails.contactPerson);
    let phoneNumber = companyDetails.phoneNumber
      .toString()
      .replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2-$3");
    $("#phoneNumber").val(phoneNumber);
    $("#emailAddress").val(companyDetails.emailAddress);
    $("#businessTypeId").val(companyDetails.businessTypeId);
    let federalEmployeeId = companyDetails.federalEmployeeId
      .toString()
      .replace(/(\d\d)(\d\d\d\d\d\d\d)/, "$1-$2");
    $("#federalEmployeeId").val(federalEmployeeId);
    $("#businessStartDate input").val(companyDetails.businessStartDate);
    $("#effectiveDate input").val(companyDetails.effectiveDate);
    $("#expirationDate input").val(companyDetails.expirationDate);
    $("#liabilityLimitId").val(companyDetails.liabilityLimitId);
    let address = companyDetails.prospectAddresses;
    for (let key in companyDetails.prospectAddresses) {
      $("#officeLoc" + key + " input[name=addLine1]").val(
        address[key].streetLineOne
      );
      $("#officeLoc" + key + " input[name=addLine2]").val(
        address[key].streetLineTwo
      );
      $("#officeLoc" + key + " input[name=zipCode]").val(address[key].zipCode);
      $("#officeLoc" + key + " select").val(address[key].stateCode);
      $("#officeLoc" + key + " input[name=cityName]").val(address[key].city);
      $("#officeLoc" + key + " button img").attr(
        "data-id",
        address[key].addressId
      );
    }
  }
}

export function setAddressDetails() {
  if (store.getState().prospectDetails !== null) {
    $("#compNameRateComp").html(store.getState().prospectDetails.companyName);
    let prospectAddresses = store.getState().prospectDetails.prospectAddresses;
    for (let key in prospectAddresses) {
      let stateCode = prospectAddresses[key].stateCode;
      var addressLine2 = "";
      if (prospectAddresses[key].streetLineTwo !== "None") {
        addressLine2 = prospectAddresses[key].streetLineTwo;
      }
      let fullAddress =
        prospectAddresses[key].streetLineOne +
        ", " +
        addressLine2 +
        ", " +
        prospectAddresses[key].stateCode +
        ", " +
        prospectAddresses[key].city +
        ", " +
        prospectAddresses[key].zipCode;
      let tempAddress = fullAddress
        .split(",")
        .filter((e) => e.trim().length > 0)
        .join(",");
      $("#addBlock" + key + " .addressFull").html(tempAddress);
      $("#addBlock" + key + " .addressFull").attr(
        "data-addressId",
        prospectAddresses[key].addressId
      );
      $("#addBlock" + key + " input[name=classCode]").attr(
        "data-statecode",
        stateCode
      );
      // $('#stateAggName' + stateCode).html(stateCode);
    }
  }
}

export function setHistoricalClaims() {
  let result = {};
  let historicalClaimsCheck = {};
  let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
  let histClaims = currProspect.historicalClaims;
  let currYear = new Date(currProspect.effectiveDate).getFullYear();
  for (let i = 0; i < 5; i++) {
    historicalClaimsCheck[currYear] = true;
    currYear--;
  }
  histClaims.forEach((e) => {
    historicalClaimsCheck[e.year] = false;
    result[e.year] = {
      totPaid: e.totPaid,
      totInc: e.totInc,
      carrier: e.carrier,
      totRes: e.totRes,
      annPre: e.annPre,
      claim: e.claim,
    };
  });
  this.setState({ historicalClaims: result, historicalClaimsCheck });
}

export function setUWQues(quesData) {
  let { quesList } = this.state;
  for (let quesIndex in quesList) {
    try {
      if (quesData[quesIndex].resp === "Y") {
        quesList[quesIndex].response = true;
      } else {
        quesList[quesIndex].response = false;
      }
      quesList[quesIndex].remarks = quesData[quesIndex].remarks;
    } catch (error) {}
  }
  this.setState({ quesList });
}

export function setAddUWQues(quesData) {
  let { quesListAdd } = this.state;
  for (let i = 0; i < 3; i++) {
    let quesIndex = Number(i) + 25;
    if (quesData[quesIndex].resp === "Y") {
      quesListAdd[i].response = true;
    } else {
      quesListAdd[i].response = false;
    }
    quesListAdd[i].remarks = quesData[quesIndex].remarks;
  }
  for (let i = 3; i < quesListAdd.length; i++) {
    let quesIndex = Number(i) + 25;
    if (quesData[quesIndex].resp === "Y") {
      quesListAdd[i].response = true;
      if (quesData[quesIndex].rate1) {
        quesListAdd[i].rates.rate1 = quesData[quesIndex].rate1;
        quesListAdd[i].rates.date1 = quesData[quesIndex].date1;
      }
      if (quesData[quesIndex].rate2) {
        quesListAdd[i].rates.rate2 = quesData[quesIndex].rate2;
        quesListAdd[i].rates.date2 = quesData[quesIndex].date2;
      }
    } else {
      quesListAdd[i].response = false;
    }
  }
  this.setState({ quesListAdd });
}

export function cookie() {
  if (
    decodeURIComponent(document.cookie) === "" ||
    decodeURIComponent(document.cookie) === null ||
    decodeURIComponent(document.cookie) === undefined
  ) {
    return false;
  } else {
    let x = decodeURIComponent(document.cookie);
    let y = x.split(";");
    for (let element of y) {
      if (element.split("=")[0].trim() === "InsureProProspectIdentifier") {
        return element.split("=")[1];
      }
    }
  }
}

export function cookie2() {
  if (
    decodeURIComponent(document.cookie) === "" ||
    decodeURIComponent(document.cookie) === null ||
    decodeURIComponent(document.cookie) === undefined
  ) {
    return false;
  } else {
    let x = decodeURIComponent(document.cookie);
    let y = x.split(";");
    for (let element of y) {
      if (element.split("=")[0].trim() === "InsureProProspectAddressCount") {
        return element.split("=")[1];
      }
    }
  }
}

export function setCookie(prospectId, locCount, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    "InsureProProspectIdentifier =" + prospectId + ";" + expires + ";path=/";
  document.cookie =
    "InsureProProspectAddressCount =" + locCount + ";" + expires + ";path=/";
}

export function deleteAddress(addressId) {
  let addressCount = cookie2();
  if (
    addressCount > 1 &&
    addressCount >= addressId &&
    sessionStorage.getItem("currProspect") !== null &&
    JSON.parse(sessionStorage.getItem("currProspect")).prospectDetails !== null
  ) {
    console.log(addressId);
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let allAddress = currProspect.prospectAddresses;
    delete allAddress[addressId];
    currProspect.prospectAddresses = allAddress;
    store.dispatch(window.updateDetails(currProspect));
  }
}
