import React, { useState } from "react";

// const EMPTY_OBJ = {
//     title: "",
//     children: <></>,
//     acceptBtn: "",
//     acceptBtnCallback: () => { },
//     show: false,
// };

export const ErrorPopUp = (props) => {
  let { dataToShow } = props;
  let { show, title, children, acceptBtn, acceptBtnCallback } = dataToShow;

  return (
    <div className={show ? "popup d-block" : "popup d-none"}>
      <div className="error-pop-up-container">
        <h4 className="header-text">{title}</h4>
        {children}
        <div className="d-flex justify-content-center mt-3">
          <div className="d-flex w-50 justify-content-center">
            <button
              className="btn upload-docs-action"
              onClick={() => {
                acceptBtnCallback
                  ? acceptBtnCallback()
                  : console.log("popup closed");
              }}
            >
              {acceptBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
