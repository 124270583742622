import React, { Component } from "react";
import DropZones from "../../common/DropZones";
import { Form } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../../config";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
  clearErrHistoricalClaims,
} from "../../../utils/common";
import {
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  handleCheck,
} from "../../../utils/form_cmprRates";

class LossInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: {
        first: {},
        second: {},
      },
      isNoLossChckd: false,
      loadingNoLoss: false,
      historicalClaimsError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      startDate: moment(),
      quoteStatus: "",
    };
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Check if fileListToStore prop has changed
    let { fileListToStore } = this.props;
    if (fileListToStore?.length > 0) {
      if (prevProps.fileListToStore !== this.props.fileListToStore) {
        // debugger;

        let fileList = {
          first: {},
          second: {},
        };
        for (let row in fileListToStore) {
          if (!fileList[fileListToStore[row].keyToFile])
            fileList[fileListToStore[row].keyToFile] = {};
          fileList[fileListToStore[row].keyToFile][
            fileListToStore[row].key.split("/").reverse()[0]
          ] = "#empty";
        }
        // Update state with new fileList
        this.setState({ fileList });
      }
    }
  }

  handleCheckClicked = async () => {
    let { isNoLossChckd } = this.state;
    this.setState({
      isNoLossChckd: !isNoLossChckd,
    });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }
    this.setState({ historicalClaims });
  };

  handleDateSelected = (date) => {
    if (date) {
      this.setState({ startDate: date });
      this.generateNoLoss(date);
    }
  };

  generateNoLoss = async (date) => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let sortKeyList = sessionStorage.getItem("sortKeyList")
      ? JSON.parse(sessionStorage.getItem("sortKeyList"))
      : undefined;

    if (currProspect) {
      this.setState({ loadingNoLoss: true });
      let cName = `${currProspect.companyProfile.firstName.value} ${currProspect.companyProfile.lastName.value}`;
      await axios
        .post(
          awsUrl2 + "/api/getNoLossData",
          JSON.stringify({
            company: currProspect.companyProfile.companyName.value,
            name: cName,
            dFrom: date,
            fein: currProspect.companyProfile.fein.value,
          })
        )
        .then(async (res) => {
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);

          var fileURL2 = URL.createObjectURL(file);

          // let dataToSend = {
          //   user_email_id: currProspect.companyProfile.emailId.value,
          //   uuid_carrier: sortKeyList[0],
          //   nolossGenerated: true,
          //   dFrom: date,
          // };
          // await axios
          //   .post(awsUrl2 + "/api/updateSolveUserStatusTable", dataToSend)
          //   .then((res) => {})
          //   .catch((error) => {
          //     console.log("error in API-updateSolveUserStatusTable", error);
          //   });
          this.setState({ fileURL2 });
          this.setState({ loadingNoLoss: false });
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error.");
          this.setState({ loadingNoLoss: false });
        });
    }
  };

  createTable = () => {
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    if (!currProspectDetails) return [];
    let table = [];
    let j = 0;
    let {
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
    } = this.state;

    historicalClaimsErrorStore = !historicalClaimsErrorStore
      ? {}
      : historicalClaimsErrorStore;
    historicalClaims = !historicalClaims ? {} : historicalClaims;
    historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

    let yearsInBusiness = Number(
      currProspectDetails.companyProfile.yearsInBusiness.value
    );
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear = new Date().getFullYear();
    rowIdDupYear = new Date().getFullYear();
    let historyLimitYear = rowIdYear - yearsInBusiness;

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      // if (historicalClaimsCheck[absYear]) {
      //   backgroundColor = "#dddddd";
      // } else {
      //   backgroundColor = "";
      // }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        // loss_data_index = loss_data
        //   ? loss_data.findIndex((e) => e.year === rowId)
        //   : null;
        table.push(
          <div className="row">
            <tr id={rowIdDup} key={rowIdDup}>
              <td className="col-4" id="carrier">
                <Form.Group>
                  <label
                    htmlFor={`carrier-${absYear}`}
                    className="font-family-montserrat-semi-bold"
                  >
                    Carrier
                  </label>
                  <input
                    autoComplete="off"
                    id={`carrier-${absYear}`}
                    type="text"
                    name="carText"
                    onChange={(e) => this.handleChange(e, "carrier")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].carrier
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "carrier");
                    }}
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["carrier"]
                        ? historicalClaims[absYear]["carrier"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              <td className="col-3" id="Annaulprem">
                <Form.Group>
                  <label
                    htmlFor={`annPre-${absYear}`}
                    title="Annual Premium"
                    // style={{ fontSize: "12px" }}
                    className="font-family-montserrat-semi-bold"
                  >
                    Annual Premium
                  </label>
                  <NumericFormat
                    name="anualPremium"
                    id={`annPre-${absYear}`}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "annPre");
                    }}
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "annPre")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].annPre
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["annPre"]
                        ? historicalClaims[absYear]["annPre"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              <td className="col-3" id="claims">
                <Form.Group>
                  <label
                    title="Number of claims"
                    htmlFor={`claims-${absYear}`}
                    className="font-family-montserrat-semi-bold"
                  >
                    # Claims
                  </label>
                  <NumericFormat
                    name="claims"
                    id={`claims-${absYear}`}
                    maxLength="2"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "claim");
                    }}
                    onChange={(e) => this.handleChange(e, "claim")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].claim
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["claim"]
                        ? historicalClaims[absYear]["claim"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              <td className="col-4" id="totalincurred">
                <Form.Group>
                  <label
                    htmlFor={`totInc-${absYear}`}
                    title="Total Incurred"
                    className="font-family-montserrat-semi-bold"
                  >
                    Total Incurred
                  </label>
                  <NumericFormat
                    onFocus={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "totInc");
                    }}
                    id={`totInc-${absYear}`}
                    autoComplete="off"
                    name="totInc"
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "totInc")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].totInc
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    // onBlur={(e) => this.zeroDefault(e, "totInc")}
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["totInc"]
                        ? historicalClaims[absYear]["totInc"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              {/* <td className="per10">
              <Form.Group>
                <label
                  htmlFor={`totPaid-${absYear}`}
                  title="Total Paid"
                  className="font-family-montserrat-semi-bold"
                >
                  Total Paid
                </label>
                <NumericFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totPaid");
                  }}
                  onBlur={this.handleTotPaidBlur}
                  id={`totPaid-${absYear}`}
                  autoComplete="off"
                  name="totPaid"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totPaid")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totPaid
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totPaid"]
                      ? historicalClaims[absYear]["totPaid"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per10">
              <Form.Group>
                <label
                  htmlFor={`totRes-${absYear}`}
                  title="Total Reserves"
                  className="font-family-montserrat-semi-bold"
                >
                  Total Reserves
                </label>
                <NumericFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totRes");
                  }}
                  id={`totRes-${absYear}`}
                  autoComplete="off"
                  name="totRes"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totRes")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totRes
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center "
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totRes"]
                      ? historicalClaims[absYear]["totRes"]
                      : ""
                  }
                />
              </Form.Group>
            </td> */}
            </tr>
          </div>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear > yearId}
                checked={
                  historyLimitYear > yearId || historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear] || true
                    : false
                }
                onChange={(e) => this.handleCheck(e)}
              />
              No coverage in {yearId}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };

  render() {
    let { styles, updatePopUpData } = this.props;
    let {
      fileList,
      isNoLossChckd,
      loadingNoLoss,
      quoteStatus,
      historicalClaimsError,
      startDate,
    } = this.state;
    return (
      <section className={styles.lossSection}>
        <div className="sectionContent">
          <div className="">
            <DropZones
              updatePopUpData={updatePopUpData}
              fileList={fileList}
              fileKeyName="fileList"
              displayArray={[true, true, false]}
            />
          </div>
          <div className="d-flex mt-4 p-1 historical_no_claims align-items-center">
            <input
              type="checkbox"
              id="chck-1"
              name={"chck"}
              checked={isNoLossChckd}
              disabled={loadingNoLoss || quoteStatus === "proceed_uw"}
              onClick={this.handleCheckClicked}
            />
            <label for="chck-1">No historical claims</label>
          </div>
          {/* <div id="datepicker-startDate">
            {isNoLossChckd && (
              <>
                <label
                  for="startDate"
                  title="Business start date"
                  className="mr-2 "
                >
                  Business Start Date
                </label>
                <span id="startDate">
                  <DatePicker
                    selected={startDate ? moment(startDate).toDate() : null}
                    onChange={this.handleDateSelected}
                    // maxDate={moment()}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    disabled={quoteStatus === "proceed_uw"}
                  />
                </span>
              </>
            )}
          </div> */}
          {!isNoLossChckd && (
            <div className="formTitle historical-claims mb-3">
              <h4 className="history-header">
                <h5 className="font-family-montserrat-bold">
                  Historical claims information
                </h5>
              </h4>
            </div>
          )}
          {!isNoLossChckd && (
            <>
              {historicalClaimsError && (
                <p id="claims-error" className="text-danger">
                  Claims submitted should be for consecutive years!
                </p>
              )}
              {/* <hr /> */}

              <table id="claimTable" className="grey-table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>{this.createTable()}</tbody>
              </table>
            </>
          )}
        </div>
      </section>
    );
  }
}

export default React.memo(LossInformation);
