import React from "react";

const EMTY_OBJ = {
  title: "",
  children: <></>,
  acceptBtn: "",
  rejectBtn: "",
  acceptBtnCallback: () => {},
  rejectBtnCallback: () => {},
  show: false,
};

class Popup extends React.Component {
  render() {
    let { popupData, updatePopUpData } = this.props;
    let {
      show,
      title,
      children,
      acceptBtn,
      acceptBtnCallback,
      rejectBtn,
      rejectBtnCallback,
      disableNoButton,
    } = popupData;

    return (
      <div className={show ? "popup d-block" : "popup d-none"}>
        <div className="popup-container">
          <h4 className="header-text">{title}</h4>
          {children}
          <div className="d-flex justify-content-center mt-3">
            <div
              className={`d-flex w-50 ${
                disableNoButton
                  ? "justify-content-center"
                  : "justify-content-between"
              }`}
            >
              <button
                className="btn upload-docs-action"
                onClick={() => {
                  acceptBtnCallback
                    ? acceptBtnCallback()
                    : console.log("popup closed");
                  updatePopUpData(EMTY_OBJ);
                }}
              >
                {acceptBtn}
              </button>
              {!disableNoButton && (
                <button
                  className="btn upload-docs-action"
                  onClick={() => {
                    rejectBtnCallback
                      ? rejectBtnCallback()
                      : console.log("popup closed");
                    updatePopUpData(EMTY_OBJ);
                  }}
                >
                  {rejectBtn ? rejectBtn : "No"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
