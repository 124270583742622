import { Auth } from "aws-amplify";
import carrierList from "./carrierList.json";

const itemExists = (list, value) => {
  let response = false;

  for (let listItem of list) {
    if (JSON.stringify(listItem) === JSON.stringify(value)) {
      response = true;
      break;
    }
  }

  return response;
};

export const getUserType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = {};
      let allPeoList = [];
      const currAuthUser = await Auth.currentAuthenticatedUser();
      let userType = currAuthUser.attributes["custom:user_type"];

      //create unique peo list
      carrierList.carrierPeoProgramList.forEach((e) => {
        let value = e.peo;
        let item = { value: value, label: e.displayPeoName };
        // let item = { value: value.split(" ").join(""), label: e.displayPeoName };
        if (!itemExists(allPeoList, item)) {
          allPeoList.push(item);
        }
      });

      allPeoList.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      })

      if (userType === "peo") {
        let userPeoList = [];
        let userGroups =
          currAuthUser.signInUserSession.accessToken.payload["cognito:groups"];

        // console.log("**userGroups: ", userGroups);

        // create list of peo that the user belongs to
        let isDemo = false;
        for (let group of userGroups) {
          if (group === process.env["REACT_APP_DEMO_GROUP"]) {
            userPeoList.push({
              value: "demo",
              label: "Demo",
            });
            isDemo = true;
          }
        }
        if (!isDemo) {
          allPeoList.forEach((peoDetails) => {
            for (let group of userGroups) {
              if (
                group.toLowerCase().includes(peoDetails.value.split(" ").join("").toLowerCase())
              ) {
                userPeoList.push(peoDetails);
                break;
              }
            }
          });
        }

        response = {
          userType,
          peoList: userPeoList,
        };
      } else {
        response = {
          userType: "agency",
          peoList: allPeoList,
        };
      }

      resolve(response);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};
