import carrierList from "../../../../../utils/carrierList.json";
import Clamp from "react-multiline-clamp";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import numeral from "numeral";
import axios from "axios";
import { Auth } from "aws-amplify";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/InfoOutlined";
const uuidv4 = require("uuid/v4");
import { awsUrl, awsUrl2 } from "../../../../../config";
import { getAvailableCarriers } from "../../../../../utils/form_ques";
const {
  carrierPeoProgramList,
  programMapping,
  demoNameMapping,
} = require("../../../../../utils/carrierList.json");
const quote_dropped = require("../../../../../utils/quote_dropped.json");

export const createSummaryTable = (data, styles = {}) => {
  try {
    let tableBody = [];
    let tableHeader = [];
    let staticColumnKeys = ["Metrics", "Billable WC", "Payroll"];

    // create table headers and rows
    for (let index in staticColumnKeys) {
      let key = staticColumnKeys[index];
      let tableRow = [<th>{key}</th>];

      for (let carrier in data) {
        let { payroll, billableWc } = data[carrier];
        let indexMap = {
          0: carrier,
          1: billableWc,
          2: payroll,
        };

        let item = indexMap[index];

        let displayCarrierName = carrierList.carrierMapping[item];
        try {
          if (process.env.REACT_APP_MODE === "beta") {
            if (demoNameMapping?.carriers?.[carrier]) {
              displayCarrierName = demoNameMapping.carriers[carrier];
            }
          }
        } catch (error) {}

        if (index == 0) {
          tableRow.push(<th>{displayCarrierName}</th>);
        } else {
          tableRow.push(
            <td>{formatToDollar(item, { maximumFractionDigits: 0 })}</td>
          );
        }
      }

      if (index == 0) {
        tableHeader.push(<tr>{tableRow}</tr>);
      } else {
        tableBody.push(<tr>{tableRow}</tr>);
      }
    }

    let table = (
      <table className={`table ${styles.table}`}>
        <thead>{tableHeader}</thead>
        <tbody>{tableBody}</tbody>
      </table>
    );

    return table;
  } catch (error) {
    return <table></table>;
  }
};

export const createModifiersTable = (
  data,
  selectedCarrier,
  overridesMap,
  updateOverridesMap,
  updateOverridesType,
  styles = {}
) => {
  try {
    let tableBody = [];
    let tableHeader = (
      <tr>
        <th>State</th>
        <th>SUTA</th>
        <th>Mark Up</th>
        <th>New SUTA %</th>
        <th>SUTA Profit</th>
        <th>EMOD</th>
        <th>Admin %</th>
        <th>Admin</th>
      </tr>
    );

    for (let state in data) {
      let {
        suta,
        sutaMarkup,
        newSuta,
        sutaMargin,
        overallAdminRate,
        stateBillableAdmin,
        emod,
      } = data[state];

      let tableRow = (
        <tr key={state}>
          <td>{state?.toUpperCase()}</td>
          <td>{suta === "client" ? "Client" : formatToPercent(suta)}</td>
          <td>
            <NumericFormat
              disabled={suta === "client"}
              value={
                suta === "client"
                  ? 0
                  : overridesMap?.carrierIndependent?.stateLevelModifiers
                      ?.sutaMarkup?.[state] !== null
                  ? overridesMap?.carrierIndependent?.stateLevelModifiers
                      ?.sutaMarkup?.[state]
                  : sutaMarkup
              }
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              suffix="%"
              style={
                overridesMap?.carrierIndependent?.overridesType?.sutaMarkup ===
                "overall"
                  ? { backgroundColor: "lightgrey", border: "1px solid grey" }
                  : {}
              }
              onClick={() => {
                updateOverridesType({
                  type: "state",
                  key: "sutaMarkup",
                });
              }}
              onValueChange={(values, sourceInfo) => {
                updateOverridesMap({
                  state,
                  key: "sutaMarkup",
                  category: "stateLevelModifiers",
                  value: values.floatValue,
                });
              }}
              placeholder="0.00%"
            />
          </td>
          <td>{newSuta === "client" ? "Client" : formatToPercent(newSuta)}</td>
          <td>{formatToDollar(sutaMargin)}</td>
          <td>{formatToPercent(emod)}</td>
          <td>
            <NumericFormat
              value={
                overridesMap?.carrierIndependent?.stateLevelModifiers?.admin?.[
                  state
                ] !== null
                  ? overridesMap?.carrierIndependent?.stateLevelModifiers
                      ?.admin?.[state]
                  : overallAdminRate
              }
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              style={
                overridesMap?.carrierIndependent?.overridesType?.admin ===
                "overall"
                  ? { backgroundColor: "lightgrey", border: "1px solid grey" }
                  : {}
              }
              suffix="%"
              onClick={() => {
                updateOverridesType({
                  type: "state",
                  key: "admin",
                });
              }}
              onValueChange={(values, sourceInfo) => {
                updateOverridesMap({
                  state,
                  key: "admin",
                  category: "stateLevelModifiers",
                  value: values.floatValue,
                });
              }}
              placeholder="0.00%"
            />
          </td>
          <td>{formatToDollar(stateBillableAdmin)}</td>
        </tr>
      );
      tableBody.push(tableRow);
    }

    let table = (
      <table className={`table ${styles.table}`}>
        <thead>{tableHeader}</thead>
        <tbody>{tableBody}</tbody>
      </table>
    );

    return table;
  } catch (error) {
    return <table></table>;
  }
};

export const createPriceIndicationTable = (
  data,
  overridesMap,
  updateOverridesMap,
  selectedCarrier,
  styles = {}
) => {
  try {
    let tableBody = [];
    let tableHeader = (
      <tr>
        <th>State - CC</th>
        <th className={styles.description}>Description</th>
        <th>
          Manual
          <br /> WC Rate
        </th>
        <th>
          Modified
          <br /> WC Rate
        </th>
        <th>
          Tool Net
          <br /> WC Rate
        </th>
        <th>
          Final Net
          <br /> WC Rate
        </th>
        <th style={{ paddingRight: 0 }}>
          <span>Difference</span>
          <Tooltip
            title="Final Net WC Rate vs Manual WC Rate"
            placement="top"
            arrow
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </th>
        <th>Admin</th>
        <th>
          Net WC &<br /> Admin Rate
        </th>
        <th>
          Blended
          <br /> Rate
        </th>
        <th>SUTA</th>
        <th>FUTA</th>
        <th>FICA</th>
        <th>Total Tax</th>
      </tr>
    );

    for (let state in data) {
      for (let item of data[state]) {
        let {
          admin,
          blendedRate,
          classCode,
          description,
          difference,
          fica,
          futa,
          manualWcRate,
          netWcRate,
          netWcRateTool,
          netWcRateWithAdmin,
          suta,
          totalTax,
          peoNetRate,
          weightedRate,
        } = item;

        let clientWcPrice =
          overridesMap?.carrierDependent?.[selectedCarrier]?.classCodeModifiers
            ?.clientWcPrice;

        let tableRow = (
          <tr key={`${state}-${classCode}`}>
            <td>
              {state?.toUpperCase()} - {classCode}
            </td>
            <td className={styles.description}>
              <Clamp lines={1}>{description}</Clamp>
            </td>
            <td>{selectedCarrier ? formatToPercent(manualWcRate) : "-"}</td>
            <td>{selectedCarrier ? formatToPercent(weightedRate) : "-"}</td>
            <td>{selectedCarrier ? formatToPercent(netWcRateTool) : "-"}</td>
            <td>
              <NumericFormat
                value={
                  selectedCarrier
                    ? clientWcPrice?.[classCode] || netWcRate
                    : "-"
                }
                decimalScale={2}
                fixedDecimalScale
                disabled={!selectedCarrier}
                allowNegative={false}
                suffix="%"
                onKeyUp={(event) => {
                  try {
                    let formattedValue = event.target.value;
                    let value = Number(formattedValue.replace("%", ""));

                    updateOverridesMap({
                      state,
                      classCode,
                      carrier: selectedCarrier,
                      key: "clientWcPrice",
                      category: "classCodeModifiers",
                      value,
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
                placeholder="0.00%"
              />
              {}
            </td>
            <td>{selectedCarrier ? formatToPercent(difference) : "-"}</td>
            <td>{formatToPercent(admin)}</td>
            <td>
              {selectedCarrier ? formatToPercent(netWcRateWithAdmin) : "-"}
            </td>
            <td>{selectedCarrier ? formatToPercent(blendedRate) : "-"}</td>
            <td>{suta === "client" ? "Client" : formatToPercent(suta)}</td>
            <td>{formatToPercent(futa)}</td>
            <td>{formatToPercent(fica)}</td>
            <td>{formatToPercent(totalTax)}</td>
          </tr>
        );
        tableBody.push(tableRow);
      }
    }

    let table = (
      <table className={`table ${styles.table}`}>
        <thead>{tableHeader}</thead>
        <tbody>{tableBody}</tbody>
      </table>
    );

    return table;
  } catch (error) {
    return <table></table>;
  }
};

export const createPricingSummaryTable = (data, styles = {}) => {
  try {
    let tableBody = [];
    let tableHeader = (
      <tr>
        <th>State</th>
        <th>CC</th>
        <th className={styles.description}>Description</th>
        <th>Estimated Payroll</th>
        <th>WC/ Admin Rate</th>
        <th>Premium</th>
        <th>Total Burden Rate</th>
      </tr>
    );

    for (let state in data) {
      for (let item of data[state]) {
        let {
          classCode,
          description,
          netWcRateWithAdmin,
          payroll,
          billableTotalValue,
          blendedRate,
        } = item;

        let tableRow = (
          <tr key={`${state}-${classCode}`}>
            <td>{state?.toUpperCase()}</td>
            <td>{classCode}</td>
            <td className={styles.description}>
              <Clamp lines={1}>{description}</Clamp>
            </td>
            <td>{formatToDollar(payroll)}</td>
            <td>{formatToPercent(netWcRateWithAdmin)}</td>
            <td>{formatToDollar(billableTotalValue)}</td>
            <td>{formatToPercent(blendedRate)}</td>
          </tr>
        );
        tableBody.push(tableRow);
      }
    }

    let table = (
      <table className={`table ${styles.table}`}>
        <thead>{tableHeader}</thead>
        <tbody>{tableBody}</tbody>
      </table>
    );

    return table;
  } catch (error) {
    return <table></table>;
  }
};

export const createUnbundledRatesTable = (data, sutaTypeMap, styles = {}) => {
  try {
    let notes = {
      clientState: "Client SUTA State. SUTA and FUTA Caps are honored",
      peoStates: "SUTA and FUTA Caps are honored",
    };
    let tableBody = [];
    let tableHeader = (
      <tr>
        <th>State</th>
        <th>CC</th>
        <th className={styles.description}>Description</th>
        <th>Admin</th>
        <th>Net WC Rate</th>
        <th>FICA</th>
        <th>FUTA</th>
        <th>SUTA</th>
        <th>Total</th>
        <th>Notes</th>
      </tr>
    );

    console.log("++++", data);

    for (let state in data) {
      for (let item of data[state]) {
        let { classCode, description, netWcRate, fica, futa, suta, admin } =
          item;

        let total = numeral(admin).add(netWcRate).add(fica).add(futa).value();

        if (!isNaN(suta)) {
          total = numeral(total).add(suta).value();
        }

        let tableRow = (
          <tr key={`${state}-${classCode}`}>
            <td>{state?.toUpperCase()}</td>
            <td>{classCode}</td>
            <td className={styles.description}>
              <Clamp lines={1}>{description}</Clamp>
            </td>
            <td>{formatToPercent(admin)}</td>
            <td>{formatToPercent(netWcRate)}</td>
            <td>{formatToPercent(fica)}</td>
            <td>{formatToPercent(futa)}</td>
            <td>{suta === "client" ? "Client" : formatToPercent(suta)}</td>
            <td>{formatToPercent(total)}</td>
            <td>
              {sutaTypeMap?.clientStates?.includes(state?.toUpperCase())
                ? notes.clientState
                : sutaTypeMap?.peoStates?.includes(state?.toUpperCase())
                ? notes.peoStates
                : ""}
            </td>
          </tr>
        );
        tableBody.push(tableRow);
      }
    }
    let table = (
      <table className={`table ${styles.table}`}>
        <thead>{tableHeader}</thead>
        <tbody>{tableBody}</tbody>
      </table>
    );
    return table;
  } catch (error) {
    return <table></table>;
  }
};

export const createComputationOtherFactorsTable = (
  data,
  selectedCarrier,
  styles = {}
) => {
  try {
    let tableBody = [];
    let {
      cashFlow,
      totalBillRate,
      totalBillableAdmin,
      totalCommission,
      totalLossFund,
      totalWcCost,
    } = data || {};

    let displayCarrierName =
      carrierList.carrierMapping?.[selectedCarrier] || "";

    try {
      if (process.env.REACT_APP_MODE === "beta") {
        if (demoNameMapping?.carriers?.[selectedCarrier]) {
          displayCarrierName = demoNameMapping.carriers[selectedCarrier];
        }
      }
    } catch (error) {}

    let rowDataMap = [
      { key: totalBillableAdmin, label: "Billable Admin" },
      { key: totalBillRate, label: "Total Bill Rate" },
      {
        key: totalWcCost,
        label: `${displayCarrierName} WC Cost`,
      },
      {
        key: totalLossFund,
        label: `${displayCarrierName} Loss Fund`,
      },
      { key: totalCommission, label: "Commission" },
      { key: cashFlow, label: "Cash InFlow/OutFlow" },
    ];

    for (let item of rowDataMap) {
      let { key, label } = item;

      let tableRow = (
        <tr key={label}>
          <th>{label}</th>
          <td>{formatToDollar(key)}</td>
        </tr>
      );
      tableBody.push(tableRow);
    }

    let table = (
      <table className={`table ${styles.table}`}>
        <tbody>{tableBody}</tbody>
      </table>
    );

    return table;
  } catch (error) {
    return <table></table>;
  }
};

export const createComputationProfitabilityTable = (data, styles = {}) => {
  try {
    let tableBody = [];
    let { adminProfit, grossProfit, netProfit, sutaProfit, wcProfit } =
      data || {};

    let rowDataMap = [
      {
        key: grossProfit,
        label: `Gross ${grossProfit?.value < 0 ? "Loss" : "Profit"}`,
      },
      { key: wcProfit, label: `WC ${wcProfit?.value < 0 ? "Loss" : "Profit"}` },
      {
        key: adminProfit,
        label: `Admin ${adminProfit?.value < 0 ? "Loss" : "Profit"}`,
      },
      {
        key: sutaProfit,
        label: `SUI ${sutaProfit?.value < 0 ? "Loss" : "Profit"}`,
      },
      {
        key: netProfit,
        label: `Net ${netProfit?.value < 0 ? "Loss" : "Profit"}`,
      },
    ];

    let tableHeader = (
      <tr>
        <th></th>
        <th>Value ($)</th>
        <th>% of Payroll</th>
      </tr>
    );

    for (let item of rowDataMap) {
      let { key, label } = item;

      let tableRow = (
        <tr key={label}>
          <th>{label}</th>
          <td>{formatToDollar(key?.value)}</td>
          <td>{formatToPercent(key?.percentage)}</td>
        </tr>
      );
      tableBody.push(tableRow);
    }

    let table = (
      <table className={`table ${styles.table}`}>
        <thead>{tableHeader}</thead>
        <tbody>{tableBody}</tbody>
      </table>
    );

    return table;
  } catch (error) {
    return <table></table>;
  }
};

export const formatToDollar = (value, options = {}) => {
  const { maximumFractionDigits } = options;
  if (isNaN(value)) {
    return "-";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits,
  }).format(value);
};

export const formatToPercent = (value) => {
  if (isNaN(value)) {
    return "-";
  }

  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);
};

export const getCarrierOptions = (carriers, selectedCarrier) => {
  try {
    let options = [
      <option key="" value="" selected disabled>
        Select
      </option>,
    ];

    for (let carrier of carriers) {
      let displayCarrierName = carrierList.carrierMapping[carrier];

      try {
        if (process.env.REACT_APP_MODE === "beta") {
          if (demoNameMapping?.carriers?.[carrier]) {
            displayCarrierName = demoNameMapping.carriers[carrier];
          }
        }
      } catch (error) {}

      options.push(
        <option
          key={carrier}
          value={carrier}
          selected={carrier === selectedCarrier}
        >
          {displayCarrierName}
        </option>
      );
    }

    return options;
  } catch (error) {
    return <option></option>;
  }
};

export const getBrokerOptions = (brokerModifiersData, allBrokerList) => {
  try {
    let options = [
      <option key="" value="" disabled selected>
        Select
      </option>,
    ];

    for (let broker of allBrokerList) {
      options.push(
        <option
          key={broker.id}
          selected={broker.id === brokerModifiersData.name}
          value={broker.id}
        >
          {broker.name}
        </option>
      );
    }
    return options;
  } catch (error) {
    return <option></option>;
  }
};

export const submitQues = async (overridesMap) => {
  $("#loader").css("display", "block");
  try {
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    let isUwFlow = sessionStorage.getItem("uw_flow") ? true : false;
    let submissionDate = sessionStorage.getItem("submissionDate");

    let user_id = sessionStorage.getItem("user_id");

    if (!user_id) {
      $("#loader").css("display", "none");
      return;
    }

    let carrierUserData = [];
    let addQuestionResponses = [];
    let addQuestionIndexMap = {
      q25: 0,
      q26: 1,
      q27: 2,
      q28: 3,
    };

    // work on this later
    for (let question of currProspectDetails?.uwQues) {
      if (question.name in addQuestionIndexMap) {
        let responseIndex = addQuestionIndexMap[question.name];
        addQuestionResponses[responseIndex] = question.response === "Y" ? 1 : 0;
      }
    }

    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      uwQues,
      peoDetails,
      carrierList,
      riskProfile,
    } = currProspectDetails;

    if (childrenLoc && companyProfile && emodStatesData) {
      if (!companyProfile?.expectedExpiryDate?.value) {
        companyProfile.expectedExpiryDate = {
          value: moment(companyProfile.expectedStartDate.value).add(1, "year"),
        };
      }

      let dataBody = {
        common: {
          el_limits: "1000-1000-1000",
          date_from: moment(companyProfile.expectedStartDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.expectedExpiryDate.value).format(
            "YYYY-MM-DD"
          ),
          emod: [],
          questions: addQuestionResponses || [],
          sr_carriers: {},
          overridesMap,
        },
        offices: [],
      };

      let emodForMail;

      let emodStatesDataCopy = JSON.parse(JSON.stringify(emodStatesData));

      if (emodStatesDataCopy["ncci"]) {
        let ncciEmodValue1 = emodStatesDataCopy["ncci"]?.rate1?.value || "";
        let ncciEmodValue2 = emodStatesDataCopy["ncci"]?.rate2?.value || "";
        let date1 = emodStatesDataCopy["ncci"]?.date1?.value;
        let date2 = emodStatesDataCopy["ncci"]?.date2?.value;

        for (let ncci_state of emodStatesDataCopy["ncci"]?.stateList || []) {
          if (!(ncci_state in emodStatesDataCopy)) {
            emodStatesDataCopy[ncci_state] = {
              rate1: {
                value: ncciEmodValue1,
              },
              rate2: {
                value: ncciEmodValue2,
              },
              response: ncciEmodValue1 ? true : false,
              displayMore: emodStatesDataCopy["ncci"]?.displayMore,
              date1: { value: date1 || moment() },
              date2: { value: date2 || moment() },
            };
          }
        }
        delete emodStatesDataCopy["ncci"];
      }

      // console.log("emodStatesDataCopy after: ", emodStatesDataCopy);
      for (let state in emodStatesDataCopy) {
        let emodObj = {};
        if (emodStatesDataCopy[state].response) {
          emodObj = {
            value_1: emodStatesDataCopy[state].rate1.value,
            effective_date_1: moment(
              emodStatesDataCopy[state].date1.value
            ).format("YYYY-MM-DD"),
          };

          if (emodForMail == null && emodStatesDataCopy[state].rate1.value)
            emodForMail = emodStatesDataCopy[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesDataCopy[state].stateList : [state];
          if (emodStatesDataCopy[state].displayMore) {
            emodObj.value_2 = emodStatesDataCopy[state]?.rate2?.value || "";
            emodObj.effective_date_2 = moment(
              emodStatesDataCopy[state].date2.value
            ).format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }

      // console.log("dataBody: ", dataBody);
      // return;

      let carrierEligBody = {};
      let stateLocMap = {};
      let payrollMap = {};

      //proRate payroll
      let total_days = moment(
        moment(companyProfile.expectedStartDate.value).add(1, "year")
      ).diff(moment(companyProfile.expectedStartDate.value), "days");

      let dates_diff_in_days = moment(
        companyProfile.expectedExpiryDate.value
      ).diff(moment(companyProfile.expectedStartDate.value), "days");

      let prorateValue = numeral(dates_diff_in_days).divide(total_days).value();

      if (Number(prorateValue) > 1) {
        prorateValue = 1;
      } else {
        prorateValue = Math.round(Number(prorateValue) * 1000) / 1000;
      }

      dataBody.common.prorateValue = Number(prorateValue);

      let has_ca_state = false;

      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
          childrenLoc[addressBlockNo].state.value
        }_${
          (childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
        }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          suta: "",
          employees: [],
          carriers: [],
        };

        if (childrenLoc[addressBlockNo].state.value.toLowerCase() === "ca")
          has_ca_state = true;

        let key = `${name}_${addressBlockNo}`;
        if (!(key in carrierEligBody)) {
          let state = key.split("_")[3].toLowerCase();
          if (state in stateLocMap) {
            key = stateLocMap[state];
          } else {
            carrierEligBody[key] = [];
            stateLocMap[state] = key;
          }
        }

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray =
            childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          carrierEligBody[key].push({
            code,
            description: desc,
            state,
          });

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let proRatedPayroll = numeral(payroll)
            .multiply(dates_diff_in_days)
            .divide(total_days)
            .value();

          childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].proRatedPayroll = {
            value: proRatedPayroll.toString(),
          };

          let _payroll;

          try {
            if (payroll?.includes("$") && payroll?.split("$")?.[1]) {
              _payroll = payroll.split("$")[1].split(",").join("");
            } else {
              _payroll = payroll.split(",").join("");
            }
          } catch (error) {}

          let classCodeObj = {
            code,
            payroll: _payroll,
            original_payroll: _payroll,
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
            pricing:
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                .pricing &&
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
                .value
                ? childrenLoc[addressBlockNo].classCodesInfo[
                    addressChildNo
                  ].pricing.value.split("%")[0]
                : "",
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      await axios
        .post(awsUrl + "/api/getCarrierElig", JSON.stringify(carrierEligBody))
        .then(async (res) => {
          let data = res.data.eligibility,
            nonEligibleMap;
          var eligData = res.data.eligibility;
          let industries = res.data.industries;
          let details = res.data.details;

          let eligibilityPriorityMap = {
            no: 3,
            uw: 2,
            yes: 1,
          };
          let carrierEligibilityMap = {};

          for (let location in data) {
            for (let carrier in data[location]) {
              if (data[location][carrier]) {
                if (!carrierEligibilityMap[carrier]) {
                  carrierEligibilityMap[carrier] =
                    data[location][carrier].value;
                } else if (
                  eligibilityPriorityMap[data[location][carrier].value] >
                  eligibilityPriorityMap[carrierEligibilityMap[carrier]]
                ) {
                  carrierEligibilityMap[carrier] =
                    data[location][carrier].value;
                  // let excludedList = data[location][carrier].excludedList;
                  // for (let item of excludedList) {
                  //   item.payroll = payrollMap[item.state][item.class_code];
                  // }
                }
              }
            }
          }
          let availableCarrierList = getAvailableCarriers(
            carrierEligibilityMap
          );
          let userSelectedCarriers = [];
          let carriersListToSend = [];

          for (let obj of carrierList.value) {
            userSelectedCarriers.push(obj.value);
          }

          for (let carrier of carrierPeoProgramList) {
            // let value = carrier.peo;
            // value = value
            //   .toLowerCase()
            //   .split(" ")
            //   .join("");
            if (
              userSelectedCarriers.includes(carrier.carrier) &&
              availableCarrierList.includes(carrier.carrier) &&
              (peoDetails.selectedPeo === carrier.peo ||
                (peoDetails.selectedPeo === "demo" && carrier.peo === "gms"))
            )
              carriersListToSend.push(carrier);
          }

          let additional_carr_list = [];
          let have_to_add_additional_carriers_in_ca = false;
          if (
            has_ca_state &&
            (userSelectedCarriers.includes("carrier_r") ||
              userSelectedCarriers.includes("carrier_aa"))
          ) {
            have_to_add_additional_carriers_in_ca = true;
            if (!userSelectedCarriers.includes("carrier_ac")) {
              for (let carr_obj of carrierPeoProgramList) {
                if (
                  carr_obj.carrier === "carrier_ac" &&
                  (peoDetails.selectedPeo === carr_obj.peo ||
                    (peoDetails.selectedPeo === "demo" &&
                      carr_obj.peo === "gms"))
                )
                  additional_carr_list.push(carr_obj);
              }
            }
            if (!userSelectedCarriers.includes("carrier_ab")) {
              for (let carr_obj of carrierPeoProgramList) {
                if (
                  carr_obj.carrier === "carrier_ab" &&
                  (peoDetails.selectedPeo === carr_obj.peo ||
                    (peoDetails.selectedPeo === "demo" &&
                      carr_obj.peo === "gms"))
                )
                  additional_carr_list.push(carr_obj);
              }
            }
          }

          if (availableCarrierList.length > 0) {
            for (let office of dataBody.offices) {
              if (
                office.state === "ca" &&
                have_to_add_additional_carriers_in_ca
              ) {
                office.carriers.push(
                  ...carriersListToSend,
                  ...additional_carr_list
                );
              } else {
                office.carriers.push(...carriersListToSend);
              }
            }

            const fetchStatesTypeData = (
              location,
              peo,
              carrier,
              state,
              program
            ) => {
              return new Promise((resolve, reject) => {
                try {
                  axios
                    .get(awsUrl + `/api/getStateTypeData/${state}/${program}`)
                    .then((res) => {
                      res?.data?.type
                        ? resolve({
                            location,
                            peo,
                            carrier,
                            type: res.data.type,
                          })
                        : resolve({});
                    })
                    .catch((err) => {
                      throw err;
                    });
                } catch (error) {
                  console.log(error);
                  resolve({});
                }
              });
            };

            const resID = await axios.post(
              awsUrl2 + `/api/getQuotesDataId`,
              JSON.stringify({ body: dataBody })
            );

            let id = resID.data.id;

            let fetchData = async (id) => {
              let dropped = true;
              await axios
                .get(awsUrl2 + `/api/getQuotesData/${id}`)
                .then(async (res) => {
                  if (res.data === "processing") {
                    const myTimeout = setTimeout(() => fetchData(id), 5000);
                  } else if (res.data === "error") {
                    $("#loader").css("display", "none");
                    this.setState({
                      errorMessage: "Oops!! Timed Out. Please try again.",
                    });
                  } else {
                    try {
                      let urlData = res.data;
                      let quoteDynamoDbRuns;

                      let urlResponse = await axios.get(urlData.url);
                      let data = urlResponse.data;

                      let stateTypePromiseList = [];

                      if (data) {
                        for (let location in data) {
                          let locDropped = true;
                          for (let peo in data[location]) {
                            let peoDropped = true;
                            for (let carrier in data[location][peo]) {
                              if (data[location][peo][carrier] !== "Dropped") {
                                peoDropped = false;
                                dropped = false;
                                locDropped = false;
                              } else {
                                let manual_rate_values = {};
                                let currState = location
                                  .split("_")
                                  .reverse()[1]
                                  .toLowerCase();
                                let officesObj = dataBody.offices;
                                let employees = [];
                                let program, program_to_use;

                                for (let _program in programMapping) {
                                  let carrierPeoProgramObj =
                                    programMapping[_program];

                                  if (
                                    carrierPeoProgramObj.peo === peo &&
                                    carrierPeoProgramObj.carrier === carrier
                                  ) {
                                    program = _program;
                                    if (peo === "insurecomp")
                                      program_to_use = `${peo}-${carrierPeoProgramObj.actualCarrierName}`;
                                    break;
                                  }
                                }

                                stateTypePromiseList.push(
                                  fetchStatesTypeData(
                                    location,
                                    peo,
                                    carrier,
                                    currState,
                                    program_to_use?.toLowerCase() ||
                                      program?.toLowerCase()
                                  )
                                );

                                for (let office of officesObj) {
                                  if (office.state === currState)
                                    employees = office.employees;
                                }

                                for (let employee of employees) {
                                  manual_rate_values[employee.code] = 0;
                                }

                                //delete carrier from data
                                data[location][peo][carrier] = JSON.parse(
                                  JSON.stringify(quote_dropped)
                                );

                                data[location][peo][carrier].peo = peo;
                                data[location][peo][carrier].program = program;
                                data[location][peo][carrier].carriers =
                                  carriersListToSend;
                                data[location][peo][carrier].el_limits =
                                  dataBody.common.el_limits;
                                data[location][peo][carrier].date_from =
                                  dataBody.common.date_from;
                                data[location][peo][carrier].date_to =
                                  dataBody.common.date_to;
                                data[location][peo][carrier].carrier = carrier;
                                data[location][peo][carrier].carrier_email =
                                  carrier + "@gmail.com";
                                data[location][peo][carrier].employees =
                                  employees;
                                data[location][peo][
                                  carrier
                                ].original_employees = employees;
                                data[location][peo][
                                  carrier
                                ].manual_rate_values = manual_rate_values;
                                data[location][peo][carrier].net_rate_values =
                                  manual_rate_values;
                                data[location][peo][
                                  carrier
                                ].manual_premium_values = manual_rate_values;
                                data[location][peo][carrier].state = currState;
                                data[location][peo][carrier].name = location;
                              }
                            }
                          }
                        }

                        let stateTypePromiseDataList = await Promise.all(
                          stateTypePromiseList
                        );
                        for (let stateTypeData of stateTypePromiseDataList) {
                          let { location, peo, carrier, type } = stateTypeData;

                          if (location && peo && carrier && type) {
                            data[location][peo][carrier].masterOrMcp = type;
                          }
                        }

                        if (true) {
                          let prospectAddresses = {};

                          // create the prospect address object
                          for (let stateIndex in childrenLoc) {
                            prospectAddresses[stateIndex] = {
                              stateCode: childrenLoc[stateIndex].state.value,
                              addressId: childrenLoc[stateIndex].number,
                              classCodes: [],
                            };
                            for (let classCodeIndex in childrenLoc[stateIndex]
                              .classCodesInfo) {
                              let classCodeDescArray =
                                childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].classCodeDescription.value.split(":");

                              let code = classCodeDescArray.shift();
                              let desc = classCodeDescArray.join(":").trim();

                              prospectAddresses[stateIndex].classCodes.push({
                                code,
                                payroll: numeral(
                                  childrenLoc[stateIndex].classCodesInfo[
                                    classCodeIndex
                                  ].payroll.value
                                )
                                  .value()
                                  .toString(),
                                pt: childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].pt.value.trim(),
                                ft: childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].ft.value.trim(),
                                description: desc,
                              });
                            }
                          }

                          let currentDate = Math.floor(Date.now()).toString();
                          let visitTimestamp =
                            sessionStorage.getItem("visitTimestamp");
                          let uuid = sessionStorage.getItem("uuid");
                          if (!uuid) {
                            uuid = uuidv4();
                          }
                          let sortKeyList = [];
                          let address = [];
                          let quoteData;
                          let class_code_object = {};

                          for (let location in eligData) {
                            for (let carrier in eligData[location]) {
                              for (let ele of carrierPeoProgramList) {
                                let place = location
                                  .split("_")
                                  .slice(0, 5)
                                  .join("_");
                                if (
                                  ele.carrier === carrier &&
                                  data[place] &&
                                  ele.peo in data[place] &&
                                  data[place][ele.peo][carrier] &&
                                  data[place][ele.peo][carrier] !== "Dropped"
                                ) {
                                  if (!(place in class_code_object))
                                    class_code_object[place] = {};
                                  if (!(ele.peo in class_code_object[place]))
                                    class_code_object[place][ele.peo] = {};

                                  class_code_object[place][ele.peo][carrier] =
                                    eligData[location][
                                      carrier
                                    ].classCodeEligibility;
                                }
                              }
                            }
                          }
                          let isLoggedIn =
                            await Auth.currentAuthenticatedUser();

                          let salesforceData;
                          try {
                            salesforceData = JSON.parse(
                              sessionStorage.getItem("salesforceData")
                            );
                          } catch (error) {}

                          for (let place in data) {
                            address.push(place);
                            for (let peo in data[place]) {
                              let tempData = {
                                producerName:
                                  currProspectDetails?.["companyProfile"]?.[
                                    "producer"
                                  ]?.["value"] || "Paul Hughes",
                                producerEmail:
                                  isLoggedIn.attributes.email || " ",
                                producerFax:
                                  isLoggedIn.attributes["custom:fax"] || " ",
                                producerPhone:
                                  isLoggedIn.attributes["custom:phoneNo."] ||
                                  " ",
                                producerMobile:
                                  isLoggedIn.attributes["custom:mobile"] || " ",
                                user_email_id: user_id,
                                uuid_carrier: `${currentDate}@${uuid}_${peo}+${place}`,
                                date: currentDate,
                                visitDate: visitTimestamp,
                                effective_date: moment(
                                  companyProfile.effectiveDate.value
                                ).format("YYYY-MM-DD"),
                                expiration_date: moment(
                                  companyProfile.expectedExpiryDate.value
                                ).format("YYYY-MM-DD"),
                                prospect_addresses: prospectAddresses,
                                phone_number: companyProfile.phoneNumber.value,
                                company_name: companyProfile.companyName.value,
                                fein: companyProfile.fein.value,
                                liability_limitId: "1000-1000-1000",
                                uuid: uuid,
                                peo: `${peo}+${place}`,
                                // carrier_email_id: data[place][peo][carrier].carrier_email,
                                carrier_location_data: data[place][peo],
                                doc_status: "not_uploaded",
                                currProspectDetails,
                                uw_ques: uwQues,
                                industries,
                                details,
                                classCodeEligibility:
                                  class_code_object?.[place]?.[peo],
                                // data[place][peo][carrier].classCodeEligibility,
                                requestApprovalEnabled: true,
                                peoCharges: {
                                  administrativeServicekey: [
                                    {
                                      question:
                                        "Payroll Administration Per Check Processing Fee",
                                      questionId: 0,
                                      dropDown: "yes",
                                      options: ["included", "excluded"],
                                      response: "included",
                                    },
                                    {
                                      question:
                                        "Direct Deposit & Payroll Card Administration",
                                      questionId: 1,
                                      dropDown: "yes",
                                      options: ["included", "excluded"],
                                      response: "included",
                                    },
                                    {
                                      question:
                                        "HR Administration & Consulting Administration",
                                      questionId: 2,
                                      dropDown: "yes",
                                      options: ["included", "excluded"],
                                      response: "included",
                                    },
                                    {
                                      question:
                                        "Safety & Claims Administration",
                                      questionId: 3,
                                      dropDown: "yes",
                                      options: ["included", "excluded"],
                                      response: "included",
                                    },
                                    {
                                      question:
                                        "Medical, Dental & Vision Benefits Administration",
                                      questionId: 4,
                                      dropDown: "yes",
                                      options: ["included", "excluded"],
                                      response: "included",
                                    },
                                    {
                                      question: "125 Cafeteria Plan",
                                      questionId: 5,
                                      dropDown: "yes",
                                      options: ["included", "excluded"],
                                      response: "included",
                                    },
                                    {
                                      question: "Year End W2 Processing Set up",
                                      questionId: 6,
                                      dropDown: "no",
                                      response: 150,
                                    },

                                    {
                                      question:
                                        "Year End W2 Processing Per Employee Cost",
                                      questionId: 7,
                                      dropDown: "no",
                                      response: 3.25,
                                    },
                                    {
                                      question:
                                        "Waiver of Subrogation Per Waiver Fee",
                                      questionId: 8,
                                      dropDown: "no",
                                      response: 125,
                                    },

                                    {
                                      question:
                                        "Employment Practice Liability Insurance Per Annum, per employee (pro rated by payroll cycle)",
                                      questionId: 9,
                                      dropDown: "no",
                                      response: 128,
                                    },
                                    {
                                      question:
                                        "Client Company Cyber Insurance Per Annum, per employee (pro rated by payroll cycle)",
                                      questionId: 10,
                                      dropDown: "no",
                                      response: 19.5,
                                    },
                                  ],
                                  optionalAdditionalServiceKey: [
                                    {
                                      question:
                                        "Wage & Hour Endorsement for Employment Practice Liability Insurance Per Annum, per company (pro rated by payroll cycle) ($50k policy limit)",
                                      questionId: 0,
                                      dropDown: "no",
                                      response: "Priced on Request",
                                    },
                                    {
                                      question:
                                        "Master 401 (K) Per Employee Set Up (one time fee)",
                                      questionId: 1,
                                      dropDown: "no",
                                      response: "Priced on Request",
                                    },
                                    {
                                      question:
                                        "Master 401 (K) Per Employee Annual Maintenance",
                                      questionId: 2,
                                      dropDown: "no",
                                      response: "Priced on Request",
                                    },
                                    {
                                      question: "401 (k) Administration",
                                      questionId: 3,
                                      dropDown: "no",
                                      response: "Priced on Request",
                                    },
                                    {
                                      question:
                                        "Time & Attendance GPS Enabled Smart Timeclock App Per Annum, per employee (pro rated by payroll cycle)",
                                      questionId: 4,
                                      dropDown: "no",
                                      response: "Priced on Request",
                                    },
                                    {
                                      question:
                                        "Onsite Check Printer Lease (includes MICR toner, check stock and envelopes) Per Annum (pro rated by payroll cycle)",
                                      questionId: 5,
                                      dropDown: "no",
                                      response: 510,
                                    },
                                    {
                                      question:
                                        "Onsite Check Printer Purchase (includes MICR toner, check stock and envelopes) One time purchase",
                                      questionId: 6,
                                      dropDown: "no",
                                      response: 495,
                                    },
                                  ],
                                  programDetailsAdditionalServiceKey: [
                                    {
                                      question:
                                        "Claim Contribution Fee per Incident",
                                      questionId: 0,
                                      dropDown: "yes",
                                      options: [
                                        0, 10, 500, 1250, 2500, 5000, 7500,
                                      ],
                                      response: 2500,
                                    },
                                    {
                                      question:
                                        "Claim Contribution Fee Increase for Late Claim Reporting Penalty per Incident (claims reported to IES more than 5 working days after incident)",
                                      questionId: 1,
                                      dropDown: "yes",
                                      options: [
                                        0, 10, 500, 1250, 2500, 5000, 7500,
                                      ],
                                      response: 2500,
                                    },
                                    {
                                      question: "Set Up Fee",
                                      questionId: 2,
                                      dropDown: "yes",
                                      options: [
                                        0, 10, 500, 1250, 2500, 5000, 7500,
                                      ],
                                      response: 0,
                                    },
                                    {
                                      question: "Deposit",
                                      questionId: 3,
                                      dropDown: "yes",
                                      options: [
                                        0, 10, 500, 1250, 2500, 5000, 7500,
                                      ],
                                      response: 0,
                                    },
                                    {
                                      question:
                                        "Minimum Invoice Charge (Program maintenance fee charged per payroll cycle)",
                                      questionId: 4,
                                      dropDown: "yes",
                                      options: [
                                        0, 10, 500, 1250, 2500, 5000, 7500,
                                      ],
                                      response: 0,
                                    },
                                  ],
                                },
                              };

                              if (salesforceData) {
                                tempData.salesforceData = salesforceData;
                              }

                              if (isUwFlow) {
                                tempData.submissionDate = submissionDate;
                                tempData.quoteSubmitted = "true";
                              }

                              sortKeyList.push(tempData.uuid_carrier);
                              carrierUserData.push(tempData);
                            }
                          }

                          quoteData = {
                            uuid,
                            date: currentDate,
                          };

                          sessionStorage.setItem(
                            "sortKeyList",
                            JSON.stringify(sortKeyList)
                          );
                          sessionStorage.setItem(
                            "quoteData",
                            JSON.stringify(quoteData)
                          );
                          sessionStorage.setItem(
                            "address",
                            JSON.stringify(address)
                          );

                          let prevIndex = 0;
                          let nextIndex = 5;
                          let uploadData;
                          let postUsersDataPromiseList = [];

                          for (
                            let i = 0;
                            i < Math.floor(carrierUserData.length / 5) + 1;
                            i++
                          ) {
                            uploadData = carrierUserData.slice(
                              prevIndex,
                              nextIndex
                            );
                            if (uploadData.length > 0) {
                              postUsersDataPromiseList.push(
                                axios
                                  .post(
                                    awsUrl + "/api/postUsersData",
                                    uploadData
                                  )
                                  .catch((error) => {
                                    console.log("error in posting data", error);
                                  })
                              );
                            }

                            prevIndex = nextIndex;
                            nextIndex = nextIndex + 5;
                          }

                          await Promise.allSettled(
                            postUsersDataPromiseList
                          ).then(async (res) => {
                            let emailID = isLoggedIn.attributes.email;
                            let userDetails = {
                              user_email_id: user_id,
                              uw_ques: uwQues,
                              status: "quote_generated",
                              uploadTimestamp: currentDate,
                              modifiedBy: emailID,
                              quoteData,
                              address,
                              riskProfile: riskProfile,
                              childrenLoc: childrenLoc,
                              companyProfile: companyProfile,
                              emodStatesData: emodStatesData,
                              peoDetails,
                              carrierList,
                              requestApprovalEnabled: true,
                              approval_status: null, // reset the approval status on quote generation
                              approval_status_timestamp: null,
                            };

                            if (isUwFlow) {
                              userDetails.submissionDate = submissionDate;
                              userDetails.quoteSubmitted = "true";
                            }

                            await axios
                              .post(
                                awsUrl2 + "/api/userTableData/update",
                                userDetails
                              )
                              .then((res2) => {})
                              .catch((err) => {
                                console.log("Error:", err);
                              });

                            sessionStorage.setItem("formStage", "three");
                            $("#loader").css("display", "none");
                            window.location.reload();
                          });
                        } else {
                          sessionStorage.setItem("formStage", "two");
                          $("#loader").css("display", "none");
                          window.location.reload();
                        }
                      }
                    } catch (error) {
                      console.log("error", error);
                      $("#loader").css("display", "none");
                      window.location.reload();
                    }
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                });
            };

            fetchData(id);
          } else {
            console.log("no carriers available");
            $("#loader").css("display", "none");
          }
        })
        .catch((err) => {
          console.log("error in eligibility api", err);
          $("#loader").css("display", "none");
        });
    }
  } catch (error) {
    console.log("error", error);
    $("#loader").css("display", "none");
  }
};

export const updateApprovalStatus = async (
  status,
  toastFunctions = {},
  carrierName,
  updatePopUpData
) => {
  $("#loader").css("display", "block");

  let userId = sessionStorage.getItem("user_id");
  let address = sessionStorage.getItem("address");
  let quoteData = sessionStorage.getItem("quoteData");
  let popupMessageMap = {
    submitted: "Submitted Successfully",
    approved: "Approved Successfully",
    rejected: "Rejected Successfully",
    requested: "Sent for Approval Successfully",
  };

  try {
    address = JSON.parse(address);
    quoteData = JSON.parse(quoteData);
    let timestamp = moment().format("x");
    let { uuid, date } = quoteData;

    let quoteRequestBody = {
      user_email_id: userId,
      timestamp: date,
      peo: "ies",
      address,
      uuid,
      approval_status: status,
      approval_status_timestamp: timestamp,
    };

    let userRequestBody = {
      user_email_id: userId,
      approval_status: status,
      approval_status_timestamp: timestamp,
    };

    let mailRequestBody = {
      userId,
      quoteData,
    };

    if (status === "requested") {
      quoteRequestBody.requestApprovalEnabled = false;
      userRequestBody.requestApprovalEnabled = false;
      mailRequestBody.receiverType = "admin";
    } else if (status === "submitted") {
      mailRequestBody = {
        ...mailRequestBody,
        receiverType: "carrier",
        carrierGroup: carrierName,
      };
    }

    let promiseList = [
      axios.post(awsUrl2 + "/api/updateUserStatusTable", quoteRequestBody),
      axios.post(awsUrl2 + "/api/userTableData/update", userRequestBody),
    ];

    if (status === "requested" || status === "submitted") {
      promiseList.push(
        axios
          .post(awsUrl2 + "/api/pricingMailHandler", mailRequestBody)
          .then((res) => {
            console.log("mail response", res.data);
            if (res?.data?.attachmentLimitExceeded && updatePopUpData) {
              // handle attachmentLimitExceeded popup here
              updatePopUpData({
                show: true,
                title: "",
                children: (
                  <h4>
                    The email has been sent without attachments as the files you
                    are attaching exceed the server limit. Please try sending a
                    link instead.
                  </h4>
                ),
                acceptBtn: "Okay",
                acceptBtnCallback: () => {},
                disableNoButton: true,
              });
            }
          })
          .catch((err) => {
            console.log("error in sending mail");
            // handle mail error popup here
          })
      );
    }

    await Promise.all(promiseList).then((res) => {
      if (popupMessageMap?.[status] && toastFunctions?.toastSuccess) {
        toastFunctions?.toastSuccess(popupMessageMap[status]);
      }

      $("#loader").css("display", "none");
    });
  } catch (error) {
    console.log("error in requestApproval", error);
    $("#loader").css("display", "none");
  }
};
