let debounce=(fun)=>{
    let timeout;
    return function(...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        fun.apply(context, args);
      }, 800);
    };
}

export default debounce;