import React from "react";
import SmallLoader from "../../common/SmallLoader";
import Clamp from "react-multiline-clamp";
import { createUnbundledRatesTable } from "../sections-quote/pricing-tool/utils/utils";

const UnbundledRate = ({
  data,
  unbundledRateDataLoader,
  styles,
  sutaTypeMap,
  selectedCarrier,
}) => {
  return (
    <div>
      <h5 className="fw-bold mt-3 mb-3">Unbundled Rate</h5>
      {!unbundledRateDataLoader && (
        <div className={`${styles.tableContainer} tableContainer`}>
          {createUnbundledRatesTable(
            data?.[selectedCarrier],
            sutaTypeMap,
            styles
          )}
        </div>
      )}
      {unbundledRateDataLoader && (
        <div className="d-flex justify-content-center mt-5 mb-5">
          <SmallLoader />
        </div>
      )}
    </div>
  );
};

export default React.memo(UnbundledRate);
