import React, { Component } from "react";
import ReactDOM from "react-dom";
import Loader from "../common/Loader";
import Navbar from "../common/Navbar";
import FormLogin from "../subcompo/sections-login/FormLogin";
import FormReset from "../subcompo/sections-login/FormReset";
import Footer from "../common/Footer";
import FormChangePass from "../subcompo/sections-login/FormChangePass";
import Header from "../common/Header";
import Stats2 from "../subcompo/sections-home/Stats2";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import SideTab from "../../newcomponent/common/SideTab";
let landingImage = require("../../images/GroupPhoto.png");
let isMobile = window.innerWidth < 900;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: false,
      formLogin: true,
      formReset: false,
      formChangePass: false,
    };
  }
  async componentDidMount() {
    try {
      ReactDOM.findDOMNode(this).scrollTop = 0;
      let sessionData = await Auth.currentAuthenticatedUser();
      if (sessionData) {
        let userGroups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        this.setState({ userGroups, loggedInUser: true });
      }
    } catch (error) {
      console.log(error);
    }
  }

  loginPageHandler = (value) => {
    console.log(value);
    if (value === "changePassword") {
      this.setState({
        formLogin: false,
        formReset: false,
        formChangePass: true,
      });
    } else if (value === "resetPassword") {
      this.setState({
        formLogin: false,
        formReset: true,
        formChangePass: false,
      });
    } else {
      this.setState({
        formLogin: true,
        formReset: false,
        formChangePass: false,
      });
    }
  };

  render() {
    let { loggedInUser, userGroups } = this.state;
    return (
      <div>
        {/* <Navbar variant="large-logo" /> */}
        <div className="d-flex">
          <Header />
          {/* <Stats2 /> */}
        </div>
        <div className="position-relative">
          {isMobile && loggedInUser ? <SideTab /> : " "}
          <img
            width="100%"
            src={landingImage}
            alt="Landing Image"
            className="Landing__page__image"
          />
          <div className="workcomp-line">
            <p className="workcomp-line-paragraph">
              Workers’ Compensation Rater
            </p>
          </div>
          {!loggedInUser ? (
            <div
              className="tab-content tab-content-login"
              style={{ marginTop: "1.2rem", marginBottom: "1.2rem" }}
            >
              {this.state.formLogin && (
                <FormLogin loginPageHandler={this.loginPageHandler} />
              )}
              {this.state.formReset && (
                <FormReset loginPageHandler={this.loginPageHandler} />
              )}
              {this.state.formChangePass && (
                <FormChangePass loginPageHandler={this.loginPageHandler} />
              )}
              <Loader />
            </div>
          ) : userGroups.includes(process.env.REACT_APP_GET_QUOTE_GROUP) ? (
            <div
              style={{
                display: "felx",
                flexDirection: "column",
                marginTop: "1.2rem",
                marginBottom: "1.2rem",
              }}
            >
              {/* <h1 style={{ textAlign: "center" }}>Already Logged In</h1> */}
              <h1 style={{ textAlign: "center" }} className="Startnewquotefont">
                <Link to={"/Quote"} style={{ color: "#497fbc" }}>
                  Start New Quote
                </Link>
              </h1>
            </div>
          ) : (
            <div></div>
          )}
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default Login;
